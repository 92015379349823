import React, { Component } from "react";
import {
  Modal,
  Tooltip,
  Radio,
  Button,
  message,
  Tabs,
  Drawer,
  Steps
} from "antd";

import AddABlock from "../img/guide/add-a-block.png";
import HtmlBlock from "../img/guide/html-block.png";
import ConfigureBlock from "../img/guide/configure-block.png";
import ToolbarVisible from "../img/guide/toolbar-visible.png";
import EditSource from "../img/guide/edit-source.png";
import CopyCode from "../img/guide/copy-code.png";
import PasteCode from "../img/guide/paste-code.png";
import UpdateCode from "../img/guide/update-code.png";
import SaveChanges from "../img/guide/save-changes.png";

class MoodleConnection extends Component {
  state = { show: "all", guideCurrent: 0 };

  embedCode = () => {
    const { courseKey } = this.props;
    const { show } = this.state;

    return `<iframe
      src="${window.location.origin}/?course=${courseKey}&view=embed&show=${show}"
      style="border: none;"
      width="98%"
      height="300"
    />`;
  };

  copyToClipboard = () => {
    var textField = document.createElement("textarea");
    textField.innerHTML = this.embedCode();

    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    message.success("Copied code snippet to clipboard");
  };

  render() {
    const { visible, onClose } = this.props;
    const { show, guideVisible, guideCurrent } = this.state;

    return (
      <>
        <Modal
          visible={visible}
          title="Connect to Moodle"
          onCancel={onClose}
          footer={[
            <Button key="cancel" onClick={onClose}>
              Close
            </Button>,
            <Button key="copy" type="primary" onClick={this.copyToClipboard}>
              Copy
            </Button>
          ]}
        >
          <Tabs type="card">
            <Tabs.TabPane tab="Contact Block" key="embed">
              <Button
                type="link"
                onClick={() => this.setState({ guideVisible: true })}
              >
                <span style={{ borderBottom: "1px dashed #1890ff" }}>
                  How do I add this to Moodle?
                </span>
              </Button>

              <Radio.Group
                onChange={e => this.setState({ show: e.target.value })}
                value={show}
                style={{ padding: 10 }}
              >
                <Radio value="all">All contacts</Radio>
                <Radio value="convenor">Convenors only</Radio>
                <Radio value="instructor">Instructors only</Radio>
              </Radio.Group>

              <div
                style={{
                  padding: "10px 20px",
                  background: "#eee",
                  border: "1px dashed #ccc",
                  position: "relative"
                }}
              >
                <Tooltip title="Copy to clipboard">
                  <Button
                    shape="circle"
                    icon="copy"
                    size="small"
                    onClick={this.copyToClipboard}
                    style={{ position: "absolute", top: 5, right: 5 }}
                  />
                </Tooltip>
                <code>{this.embedCode()}</code>
              </div>
            </Tabs.TabPane>

            <Tabs.TabPane tab="External Tool (LTI Page)" key="lti">
              This explanation is coming shortly. Please check back later.
            </Tabs.TabPane>
          </Tabs>
        </Modal>

        <Drawer
          visible={guideVisible}
          onClose={() => this.setState({ guideVisible: false })}
          width={500}
          bodyStyle={{ height: "100%" }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <h2>Adding a Contact Block to Moodle</h2>

            <Steps
              current={guideCurrent}
              direction="vertical"
              style={{ flex: 1 }}
            >
              <Steps.Step
                title="Add an HTML block"
                description={
                  <div style={{ minHeight: 10 }}>
                    {guideCurrent === 0 && (
                      <>
                        <img
                          alt="Add HTML block button"
                          src={AddABlock}
                          style={{
                            width: "100%",
                            marginBottom: 6,
                            border: "1px solid #9E9E9E",
                            borderRadius: 3
                          }}
                        />
                        <img
                          alt="Add HTML block dropdown"
                          src={HtmlBlock}
                          style={{
                            width: "100%",
                            border: "1px solid #9E9E9E",
                            borderRadius: 3
                          }}
                        />
                      </>
                    )}
                  </div>
                }
              />
              <Steps.Step
                title="Configure the HTML block"
                description={
                  <div style={{ minHeight: 10 }}>
                    {guideCurrent === 1 && (
                      <>
                        <img
                          alt="Configure HTML block"
                          src={ConfigureBlock}
                          style={{
                            width: "100%",
                            border: "1px solid #9E9E9E",
                            borderRadius: 3
                          }}
                        />
                      </>
                    )}
                  </div>
                }
              />
              <Steps.Step
                title="Show the content toolbar"
                description={
                  <div style={{ minHeight: 10 }}>
                    {guideCurrent === 2 && (
                      <>
                        <img
                          alt="Show content toolbar"
                          src={ToolbarVisible}
                          style={{
                            width: "100%",
                            border: "1px solid #9E9E9E",
                            borderRadius: 3
                          }}
                        />
                      </>
                    )}
                  </div>
                }
              />
              <Steps.Step
                title="Edit HTML source"
                description={
                  <div style={{ minHeight: 10 }}>
                    {guideCurrent === 3 && (
                      <>
                        <img
                          alt="Edit HTML source"
                          src={EditSource}
                          style={{
                            width: "100%",
                            border: "1px solid #9E9E9E",
                            borderRadius: 3
                          }}
                        />
                      </>
                    )}
                  </div>
                }
              />
              <Steps.Step
                title="Copy and paste the snippet code"
                description={
                  <div style={{ minHeight: 10 }}>
                    {guideCurrent === 4 && (
                      <>
                        <img
                          alt="Copy to clipboard"
                          src={CopyCode}
                          style={{
                            width: "100%",
                            marginBottom: 6,
                            border: "1px solid #9E9E9E",
                            borderRadius: 3
                          }}
                        />
                        <img
                          alt="Paste from clipboard"
                          src={PasteCode}
                          style={{
                            width: "100%",
                            border: "1px solid #9E9E9E",
                            borderRadius: 3
                          }}
                        />
                      </>
                    )}
                  </div>
                }
              />
              <Steps.Step
                title="Update and save"
                description={
                  <div style={{ minHeight: 10 }}>
                    {guideCurrent === 5 && (
                      <>
                        <img
                          alt="Update the HTML content"
                          src={UpdateCode}
                          style={{
                            width: "100%",
                            marginBottom: 6,
                            border: "1px solid #9E9E9E",
                            borderRadius: 3
                          }}
                        />
                        <img
                          alt="Save changes to the block"
                          src={SaveChanges}
                          style={{
                            width: "100%",
                            border: "1px solid #9E9E9E",
                            borderRadius: 3
                          }}
                        />
                      </>
                    )}
                  </div>
                }
              />
              <Steps.Step
                title="Adjust the height (if required)"
                description={
                  <div style={{ minHeight: 10 }}>
                    {guideCurrent === 6 && (
                      <>
                        The "height" attribute in the HTML snippet can be
                        manually adjusted. If the content overflows, a
                        scroll-bar is automatically shown.
                      </>
                    )}
                  </div>
                }
              />
            </Steps>

            <div>
              <Button
                style={{ marginRight: 6 }}
                onClick={() =>
                  this.setState({ guideCurrent: guideCurrent - 1 })
                }
                disabled={guideCurrent === 0}
                type="primary"
              >
                Previous step
              </Button>
              <Button
                onClick={() =>
                  this.setState({ guideCurrent: guideCurrent + 1 })
                }
                disabled={guideCurrent === 6}
                type="primary"
              >
                Next step
              </Button>
            </div>
          </div>
        </Drawer>
      </>
    );
  }
}

export default MoodleConnection;
