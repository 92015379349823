import React, { Component, useState, useRef } from "react";
import {
  Upload,
  Icon,
  Table,
  notification,
  Select,
  Spin,
  Button,
  Tabs,
  Row,
  Col,
  Alert
} from "antd";
import axios from "axios";
import _ from "lodash";

import AppContext from "../AppContext";

const expectedColumns = {
  courses: [
    "COURSE_ID",
    "COURSE_NAME",
    "COURSE_START_DT",
    "COURSE_END_DT",
    "EVALUTION_BEGIN_DT",
    "EVALUATION_END_DT",
    "EVALUATE",
    "SURVEY_QUESTIONS_STATUS",
    "SCHOOL_CODE",
    "FACULTY_CODE",
    "TERM",
    "SESSION_CODE",
    "COURSE_CODE",
    "COURSE_DESCR",
    "COMPONENT",
    "CLASS_SECTION"
  ],
  convenors: ["COURSE_ID", "USER_ID"],
  instructors: ["COURSE_ID", "USER_ID"],
  staff: ["USER_ID", "FIRST_NAME", "LAST_NAME", "EMAIL_ADDRESS"]
};

const exampleRecord = {
  courses: [
    {
      COURSE_ID: "5193-T1B-ECON5257-LEC-A",
      COURSE_NAME:
        "ECON5257 Intro Stats & Data Analysis (Course Lecture A 6390)",
      COURSE_START_DT: "25-MAR-2019",
      COURSE_END_DT: "28-APR-2019",
      EVALUTION_BEGIN_DT: "08-APR-2019",
      EVALUATION_END_DT: "02-MAY-2019",
      EVALUATE: "Yes",
      SURVEY_QUESTIONS_STATUS: "Course and Teaching Questions",
      SCHOOL_CODE: "ECON",
      FACULTY_CODE: "COMM",
      TERM: "5193",
      SESSION_CODE: "T1B",
      COURSE_CODE: "ECON5257",
      COURSE_DESCR: "Intro Stats & Data Analysis",
      COMPONENT: "LEC",
      CLASS_SECTION: "A"
    }
  ],
  convenors: [
    {
      COURSE_ID: "5193-T1B-ECON5257-LEC-A",
      USER_ID: "z1234567"
    }
  ],
  instructors: [
    {
      COURSE_ID: "5193-T1B-ECON5257-LEC-A",
      USER_ID: "z1234567"
    }
  ],
  staff: [
    {
      USER_ID: "z1234567",
      FIRST_NAME: "John",
      LAST_NAME: "Doe",
      EMAIL_ADDRESS: "z1234567@unsw.edu.au"
    }
  ]
};

const Admins = ({ facultyCode, schoolCode, admins, updateFaculty }) => {
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [staff, setStaff] = useState([]);
  const select = useRef();

  const updateAdmins = async newValue => {
    setLoading(true);
    try {
      const response = await axios.post(`admin/update_admins/`, {
        facultyCode,
        schoolCode,
        value: newValue
      });
      updateFaculty({
        facultyCode,
        schoolCode,
        field: "admins",
        value: response.data
      });
    } catch (error) {
      notification["error"]({ message: error.response.data });
    }
    setLoading(false);
    select.current.blur();
  };

  const findStaffMember = _.debounce(async filter => {
    if (!filter) return;

    try {
      setFetching(true);
      const query = await axios.get(`staff/?filter=${filter}`);
      setStaff(query.data.staff);
      if (query.data.staff.length === 0)
        notification["warning"]({
          message: "No staff were found with the provided name or zID"
        });
    } catch (error) {
      notification["error"]({ message: error.response.data });
    }
    setFetching(false);
  }, 500);

  return (
    <Select
      mode="multiple"
      defaultActiveFirstOption={false}
      style={{ width: "100%", minWidth: 250 }}
      className="editable-table-field"
      value={admins && admins.map(staffMember => staffMember.zid)}
      ref={select}
      onChange={updateAdmins}
      onSearch={findStaffMember}
      loading={loading}
      showSearch
      filterOption={false}
      dropdownMatchSelectWidth={false}
      onDropdownVisibleChange={open => {
        if (!open) setStaff([]);
      }}
      suffixIcon={
        <Icon type="loading" style={{ display: loading ? "block" : "none" }} />
      }
      placeholder="Select staff"
      notFoundContent={
        fetching ? (
          <Icon type="loading" style={{ color: "#3498db" }} />
        ) : (
          "Find staff by name or zID"
        )
      }
    >
      {staff.length > 0
        ? staff.map(staffMember => (
            <Select.Option key={staffMember.zid}>
              {staffMember.display_name} ({staffMember.zid})
            </Select.Option>
          ))
        : admins.map(
            staffMember =>
              staffMember && (
                <Select.Option key={staffMember.zid}>
                  {staffMember.display_name} ({staffMember.zid})
                </Select.Option>
              )
          )}
    </Select>
  );
};

class SchoolTable extends Component {
  render() {
    const { faculty, updateFaculty } = this.props;

    const columns = [
      {
        title: "Code",
        key: "code",
        dataIndex: "code",
        sorter: (a, b) => a.code.localeCompare(b.code),
        defaultSortOrder: "ascend"
      },
      {
        title: "School",
        key: "name",
        dataIndex: "name",
        sorter: (a, b) => a.name.localeCompare(b.name)
      },
      {
        title: "Admins",
        key: "admins",
        dataIndex: "admins",
        render: (admins, school) => (
          <Admins
            facultyCode={faculty.code}
            schoolCode={school.code}
            admins={admins}
            updateFaculty={updateFaculty}
          />
        )
      }
    ];

    return (
      <>
        <Table
          style={{ margin: "10px 0" }}
          size="small"
          bordered
          columns={columns}
          locale={{
            emptyText: "No schools match the specified criteria"
          }}
          dataSource={faculty.schools}
          rowKey={(record, i) => i}
          pagination={false}
        />
      </>
    );
  }
}

class SystemAdministration extends Component {
  static contextType = AppContext;

  state = {
    uploadType: "courses",
    loading: true,
    faculties: [],
    expandedRowKeys: []
  };

  async componentWillMount() {
    const { history } = this.props;

    try {
      const response = await axios.get(`admin/faculties/`);
      const { faculties } = response.data;
      this.setState({
        loading: false,
        faculties
      });
    } catch (error) {
      if (error.response.status === 403) {
        history.push("/forbidden");
      } else {
        history.push("/error");
      }
    }
  }

  dropFile = async ({ file, onSuccess, onError }) => {
    const { uploadType } = this.state;

    const payload = new FormData();
    payload.append("file", file, file.name);

    try {
      const response = await axios.post(
        `admin/upload_${uploadType}/`,
        payload,
        {
          config: { headers: { "Content-Type": "multipart/form-data" } }
        },
        { headers: { accept: "application/zip" }, responseType: "arraybuffer" }
      );

      let a = window.document.createElement("a");
      a.href = window.URL.createObjectURL(new Blob([response.data]), {
        type: response.headers["content-type"]
      });

      const fileName = `${uploadType}_upload_report.csv`;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      onSuccess(response);
    } catch (error) {
      onError();
      notification["error"]({ message: error.response.data, duration: 0 });
    }
  };

  updateFaculty = ({ facultyCode, schoolCode, field, value }) => {
    const { faculties } = this.state;

    const faculty = faculties.find(faculty => faculty.code === facultyCode);

    if (schoolCode) {
      const school = faculty.schools.find(school => school.code === schoolCode);
      school[field] = value;
    } else {
      faculty[field] = value;
    }

    this.setState({ faculties });
  };

  render() {
    const { uploadType, loading, faculties, expandedRowKeys } = this.state;

    const columns = [
      {
        title: "Code",
        key: "code",
        dataIndex: "code",
        sorter: (a, b) => a.code.localeCompare(b.code),
        defaultSortOrder: "ascend"
      },
      {
        title: "Faculty",
        key: "name",
        dataIndex: "name",
        sorter: (a, b) => a.name.localeCompare(b.name)
      },
      {
        title: "Admins",
        key: "admins",
        dataIndex: "admins",
        render: (admins, faculty) => (
          <Admins
            facultyCode={faculty.code}
            admins={admins}
            updateFaculty={this.updateFaculty}
          />
        )
      }
    ];

    return (
      <>
        <Tabs defaultActiveKey="data" size="large">
          <Tabs.TabPane tab="Data Upload" key="data">
            <Row
              style={{
                maxWidth: 300,
                display: "flex",
                alignItems: "center",
                marginBottom: 12
              }}
            >
              <Col span={10}>Upload type:</Col>
              <Col span={14}>
                <Select
                  onChange={uploadType => this.setState({ uploadType })}
                  style={{ width: "100%" }}
                  value={uploadType}
                >
                  <Select.OptGroup label="Course-related">
                    <Select.Option value="courses">Courses</Select.Option>
                    <Select.Option value="convenors">Convenors</Select.Option>
                    <Select.Option value="instructors">
                      Instructors
                    </Select.Option>
                  </Select.OptGroup>

                  <Select.OptGroup label="Staff-related">
                    <Select.Option value="staff">Staff List</Select.Option>
                  </Select.OptGroup>
                </Select>
              </Col>
            </Row>

            <div>
              Expected input:
              <Table
                style={{ marginTop: 6, maxWidth: 1200 }}
                size="small"
                scroll={{ x: "max-content" }}
                columns={expectedColumns[uploadType].map(column => ({
                  title: column,
                  key: column,
                  dataIndex: column
                }))}
                dataSource={exampleRecord[uploadType]}
                rowKey={(row, i) => i}
                pagination={false}
              />
              <Alert
                style={{ width: "max-content", margin: "10px 0 30px" }}
                showIcon
                type="info"
                message="Note that the order of the columns does not matter, and any additional columns will be ignored."
              />
            </div>

            <div style={{ maxWidth: 500 }}>
              <Upload.Dragger
                multiple
                accept="text/csv"
                customRequest={this.dropFile}
                name="file"
                showUploadList={{
                  showRemoveIcon: true
                }}
              >
                <p className="ant-upload-drag-icon">
                  <Icon type="inbox" />
                </p>
                <p className="ant-upload-text">
                  Click or drag file(s) here to upload
                </p>
              </Upload.Dragger>
            </div>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Faculty/School Admins" key="admins">
            <div style={{ marginBottom: 10 }}>
              <Button
                style={{ marginRight: 5 }}
                icon="plus"
                onClick={() =>
                  this.setState({
                    expandedRowKeys: faculties.map((faculty, i) => i)
                  })
                }
              >
                Expand all
              </Button>

              <Button
                icon="minus"
                onClick={() =>
                  this.setState({
                    expandedRowKeys: []
                  })
                }
              >
                Collapse all
              </Button>
            </div>

            <Table
              style={{ maxWidth: 1000 }}
              size="small"
              columns={columns}
              loading={
                loading && {
                  size: "large",
                  tip: "Loading faculties...",
                  indicator: (
                    <Spin size="large" indicator={<Icon type="loading" />} />
                  )
                }
              }
              locale={{
                emptyText: "No faculties match the specified criteria"
              }}
              dataSource={faculties}
              rowKey={(record, i) => i}
              pagination={false}
              expandedRowKeys={expandedRowKeys}
              expandedRowRender={faculty => (
                <SchoolTable
                  faculty={faculty}
                  updateFaculty={this.updateFaculty}
                />
              )}
              onExpandedRowsChange={expandedRowKeys =>
                this.setState({ expandedRowKeys })
              }
            />
          </Tabs.TabPane>
        </Tabs>
      </>
    );
  }
}

export default SystemAdministration;
