import React, { Component } from "react";
import {
  Card,
  Form,
  Input,
  TimePicker,
  Divider,
  Checkbox,
  Radio,
  Button,
  Tooltip,
  Modal,
  notification
} from "antd";
import axios from "axios";
import moment from "moment";

const consultationTypeMap = {
  timeslot: "Timeslot",
  email: "Schedule by email"
};

class Timeslot extends Component {
  constructor(props) {
    super(props);
    const { teachingWeeks, timeslot } = props;

    // Default edit mode to True if a new timeslot is being created
    // Timeslots that are being created have an id of the form "timeslot_<unique_id>"
    // whereas those coming from the database have an integer auto-increment id
    const isNewTimeslot = typeof timeslot.id === "string";

    this.state = {
      isNewTimeslot,
      editing: isNewTimeslot,
      teachingWeeks: Array.from({ length: teachingWeeks }, (v, k) => k + 1)
    };
  }

  state = { editing: true };

  readOnlyView = () => {
    const { timeslot } = this.props;
    const { teachingWeeks } = this.state;

    return (
      <>
        <h4 style={{ marginBottom: 0 }}>Consultation Type</h4>
        {consultationTypeMap[timeslot.consultation_type]}

        {timeslot.consultation_type === "timeslot" && (
          <>
            <h4 style={{ marginTop: 12 }}>Weeks</h4>
            <Checkbox.Group
              disabled
              className="timeslot weeks"
              value={timeslot.weeks}
              options={teachingWeeks.map(week => ({
                label: week,
                value: week
              }))}
            />

            <h4 style={{ marginTop: 12 }}>Days</h4>
            <Checkbox.Group
              disabled
              className="timeslot days"
              value={timeslot.days}
              options={["Mon", "Tue", "Wed", "Thu", "Fri"].map(day => ({
                label: day,
                value: day.toLowerCase()
              }))}
            />

            <h4 style={{ marginTop: 12, marginBottom: 0 }}>Time</h4>
            <div style={{ display: "flex", alignItems: "center" }}>
              {moment(timeslot.from_time, "HH:mm:ss").format("hh:mm A")}
              <Divider
                style={{
                  width: 10,
                  minWidth: "auto",
                  margin: "0 5px"
                }}
              />
              {moment(timeslot.to_time, "HH:mm:ss").format("hh:mm A")}
            </div>

            <h4 style={{ marginTop: 12, marginBottom: 0 }}>Location</h4>
            <div>{timeslot.location}</div>
          </>
        )}
      </>
    );
  };

  editView = () => {
    const { timeslot, form } = this.props;
    const { teachingWeeks } = this.state;
    const { getFieldDecorator, getFieldValue, setFieldsValue } = form;

    return (
      <>
        <h4 style={{ marginBottom: 0 }}>Consultation Type</h4>
        <Form.Item style={{ marginBottom: 6 }}>
          {getFieldDecorator("consultation_type", {
            initialValue: timeslot.consultation_type || "timeslot"
          })(
            <Radio.Group>
              <Radio value="timeslot">{consultationTypeMap["timeslot"]}</Radio>
              <Radio value="email">{consultationTypeMap["email"]}</Radio>
            </Radio.Group>
          )}
        </Form.Item>

        {getFieldValue("consultation_type") === "timeslot" && (
          <>
            <div style={{ display: "flex" }}>
              <h4>Weeks</h4>
              <Tooltip title="Deselect all weeks">
                <Button
                  size="small"
                  icon="minus"
                  shape="circle"
                  style={{ marginLeft: 5 }}
                  onClick={() => setFieldsValue({ weeks: [] })}
                />
              </Tooltip>
              <Tooltip title="Select all weeks">
                <Button
                  size="small"
                  icon="plus"
                  shape="circle"
                  style={{ marginLeft: 5 }}
                  onClick={() => setFieldsValue({ weeks: teachingWeeks })}
                />
              </Tooltip>
            </div>

            <Form.Item className="timeslot-field">
              {getFieldDecorator("weeks", {
                initialValue: timeslot.weeks || [],
                rules: [
                  {
                    required: true,
                    message: "At least one week must be selected"
                  }
                ]
              })(
                <Checkbox.Group
                  className="timeslot weeks"
                  options={teachingWeeks.map(week => ({
                    label: week,
                    value: week
                  }))}
                />
              )}
            </Form.Item>

            <div style={{ display: "flex" }}>
              <h4>Days</h4>
              <Tooltip title="Deselect all days">
                <Button
                  size="small"
                  icon="minus"
                  shape="circle"
                  style={{ marginLeft: 5 }}
                  onClick={() => setFieldsValue({ days: [] })}
                />
              </Tooltip>
              <Tooltip title="Select all days">
                <Button
                  size="small"
                  icon="plus"
                  shape="circle"
                  style={{ marginLeft: 5 }}
                  onClick={() =>
                    setFieldsValue({
                      days: ["mon", "tue", "wed", "thu", "fri"]
                    })
                  }
                />
              </Tooltip>
            </div>

            <Form.Item className="timeslot-field">
              {getFieldDecorator("days", {
                initialValue: timeslot.days || [],
                rules: [
                  {
                    required: true,
                    message: "At least one day must be selected"
                  }
                ]
              })(
                <Checkbox.Group
                  className="timeslot days"
                  options={["Mon", "Tue", "Wed", "Thu", "Fri"].map(day => ({
                    label: day,
                    value: day.toLowerCase()
                  }))}
                />
              )}
            </Form.Item>

            <h4>Time</h4>
            <div style={{ display: "flex" }}>
              <Form.Item>
                {getFieldDecorator("from_time", {
                  initialValue:
                    timeslot.from_time &&
                    moment(timeslot.from_time, "HH:mm:ss"),
                  rules: [
                    {
                      required: true,
                      message: "From time required"
                    }
                  ]
                })(
                  <TimePicker
                    placeholder="From"
                    use12Hours
                    format="h:mm A"
                    minuteStep={30}
                  />
                )}
              </Form.Item>
              <Divider
                style={{
                  width: 10,
                  minWidth: "auto",
                  margin: "21px 5px"
                }}
              />
              <Form.Item>
                {getFieldDecorator("to_time", {
                  initialValue:
                    timeslot.to_time && moment(timeslot.to_time, "HH:mm:ss"),
                  rules: [
                    {
                      required: true,
                      message: "To time required"
                    }
                  ]
                })(
                  <TimePicker
                    placeholder="To"
                    use12Hours
                    format="h:mm A"
                    minuteStep={30}
                  />
                )}
              </Form.Item>
            </div>

            <h4>Location</h4>
            <Form.Item>
              {getFieldDecorator("location", {
                initialValue: timeslot.location,
                rules: [
                  {
                    required: true,
                    message: "Location required"
                  }
                ]
              })(<Input />)}
            </Form.Item>
          </>
        )}
      </>
    );
  };

  onCancelEdit = () => {
    const { deleteTimeslot, form } = this.props;
    const { isNewTimeslot } = this.state;

    if (isNewTimeslot) {
      Modal.confirm({
        title: "Discard new contact card",
        content:
          "Are you sure you want to cancel the creation of this new contact card?",
        onOk: () => {
          deleteTimeslot();
        }
      });
    } else if (form.isFieldsTouched()) {
      Modal.confirm({
        title: "Discard changes",
        content:
          "If you proceed, any changes made to this timeslot will be lost.",
        onOk: () => {
          this.setState({ editing: false });
        }
      });
    } else {
      this.setState({ editing: false });
    }
  };

  onStartEdit = () => {
    this.setState({ editing: true });
  };

  onDelete = () => {
    const { deleteTimeslot, timeslot } = this.props;

    Modal.confirm({
      title: "Delete contact card",
      content: "Are you sure you want to delete this contact card?",
      onOk: async () => {
        this.setState({ deleting: true });

        try {
          await axios.delete(`course/delete_contact_card/${timeslot.id}`);
          notification["success"]({
            message: "Contact card successfully updated"
          });
          this.setState({ deleting: false, editing: false });
          deleteTimeslot();
        } catch (error) {
          notification["error"]({ message: error.response.data });
          this.setState({ deleting: false });
        }
      }
    });
  };

  onSave = () => {
    const { form, courseId, updateTimeslot, timeslot } = this.props;
    const { isNewTimeslot } = this.state;

    if (!form.isFieldsTouched() && !isNewTimeslot) {
      this.setState({ editing: false });
      return;
    }

    form.validateFields(async (err, values) => {
      if (err) return;

      if (values.from_time) values.from_time = values.from_time.format("HH:mm");
      if (values.to_time) values.to_time = values.to_time.format("HH:mm");

      this.setState({ saving: true });

      try {
        let response;
        if (isNewTimeslot) {
          response = await axios.post(
            `course/${courseId}/add_contact_card/`,
            values
          );
          notification["success"]({
            message: "Contact card successfully added"
          });
        } else {
          response = await axios.put(
            `course/update_contact_card/${timeslot.id}`,
            values
          );
          notification["success"]({
            message: "Contact card successfully updated"
          });
        }
        this.setState({ saving: false, editing: false });
        updateTimeslot(response.data);
      } catch (error) {
        notification["error"]({ message: error.response.data });
        this.setState({ saving: false });
      }
    });
  };

  render() {
    const { isNewTimeslot, editing, deleting, saving } = this.state;

    const actions = [
      <Tooltip
        title={
          editing
            ? isNewTimeslot
              ? "Discard new contact card"
              : "Discard changes"
            : "Delete contact card"
        }
        mouseLeaveDelay={0}
      >
        <Button
          loading={deleting}
          type="danger"
          icon={editing ? "close" : "delete"}
          onClick={editing ? this.onCancelEdit : this.onDelete}
        />
      </Tooltip>,
      <Tooltip
        title={
          editing
            ? isNewTimeslot
              ? "Save new contact card"
              : "Save changes"
            : "Edit contact card"
        }
        mouseLeaveDelay={0}
      >
        <Button
          loading={saving}
          icon={editing ? "save" : "edit"}
          onClick={editing ? this.onSave : this.onStartEdit}
        />
      </Tooltip>
    ];

    return (
      <Card className="contact-card" actions={actions}>
        {editing ? this.editView() : this.readOnlyView()}
      </Card>
    );
  }
}

export default Form.create()(Timeslot);
