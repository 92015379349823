import React, { Component } from "react";
import { Switch, Route, NavLink, Redirect, withRouter } from "react-router-dom";
import { Button, Layout, Menu } from "antd";
import { authenticatedApplication } from "react-msal-jwt";
import axios from "axios";
import preval from "preval.macro";

import EntryPoint from "./components/Entrypoint";
import Profile from "./components/Profile";
import CourseAdministration from "./components/CourseAdministration";
import SystemAdministration from "./components/SystemAdministration";

import Forbidden from "./error/Forbidden";
import ServerError from "./error/ServerError";

import AppContext from "./AppContext";

import "./App.css";

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

axios.defaults.headers.common[
  "Authorization"
] = `Bearer ${sessionStorage.getItem("access")}`;

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSystemAdmin:
        props.isSystemAdmin ||
        sessionStorage.getItem("isSystemAdmin") === "true",
      isCourseAdmin:
        props.isCourseAdmin ||
        sessionStorage.getItem("isCourseAdmin") === "true",
      hasCourse:
        props.hasCourse || sessionStorage.getItem("hasCourse") === "true"
    };

    // If mounting the component on /error or /forbidden routes,
    // then redirect to the root route
    if (["/error", "/forbidden"].includes(props.location.pathname))
      props.history.replace("/");

    // Intercept requests to detect whether the access token is still valid
    axios.interceptors.request.use(
      async config => {
        const {
          access: hasAccessToken,
          refresh: hasRefreshToken
        } = props.isTokenExpired();

        // If the access token is invalid, and we are not interacting with auth endpoints,
        // then renew the access token
        if (
          !hasAccessToken &&
          !["auth/login/", "auth/refresh/", "auth/error/"].includes(config.url)
        ) {
          if (hasRefreshToken) {
            const accessToken = await props.refreshAccessToken();
            config.headers.common["Authorization"] = `Bearer ${accessToken}`;
          } else {
            props.throwTokenError();
          }
        }

        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
  }

  render() {
    const { getAzureToken, logout, location } = this.props;
    const { isSystemAdmin, isCourseAdmin, hasCourse } = this.state;

    const routeKey = location.pathname.split("/")[1];

    return (
      <AppContext.Provider
        value={{ getAzureToken, isSystemAdmin, isCourseAdmin }}
      >
        <Layout style={{ minHeight: "100%" }}>
          <Layout.Header style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                height: "100%"
              }}
            >
              <NavLink to="/" style={{ height: "100%" }}>
                <img
                  src="https://cdn.teaching.unsw.edu.au/unswbranding/unsw_neg.png"
                  alt="UNSW logo"
                  style={{
                    maxHeight: "100%",
                    width: "auto",
                    padding: "10px 0",
                    marginRight: 25
                  }}
                />
              </NavLink>

              <Menu
                mode="horizontal"
                className="navigation-menu"
                selectedKeys={[routeKey]}
              >
                <Menu.Item key="profile">
                  <NavLink to="/profile">My Profile</NavLink>
                </Menu.Item>
                {hasCourse && (
                  <Menu.Item key="courses">
                    <NavLink to="/courses">My Courses</NavLink>
                  </Menu.Item>
                )}
                {(isSystemAdmin || isCourseAdmin) && (
                  <Menu.Item key="admin">
                    <NavLink to="/admin">Course Administration</NavLink>
                  </Menu.Item>
                )}
                {isSystemAdmin && (
                  <Menu.Item key="system">
                    <NavLink to="/system">System Administration</NavLink>
                  </Menu.Item>
                )}
              </Menu>
            </div>

            <Button icon="logout" onClick={logout}>
              Log out
            </Button>
          </Layout.Header>

          <Layout.Content style={{ padding: 25 }}>
            <Layout style={{ padding: 25, background: "#fff" }}>
              <Switch>
                <Route
                  exact
                  path="/"
                  render={() => (
                    <Redirect
                      to={
                        isSystemAdmin || isCourseAdmin ? "/admin" : "/profile"
                      }
                    />
                  )}
                />

                <Route path="/profile" component={Profile} />

                {hasCourse && (
                  <Route
                    path="/courses"
                    component={CourseAdministration}
                    key="myCourses" // Setting this key forces remount when coming from /admin
                  />
                )}

                {(isSystemAdmin || isCourseAdmin) && (
                  <Route
                    path="/admin"
                    component={CourseAdministration}
                    key="courseAdmin" // Setting this key forces remount when coming from /courses
                  />
                )}

                {isSystemAdmin && (
                  <Route path="/system" component={SystemAdministration} />
                )}

                <Route exact path="/forbidden" component={Forbidden} />
                <Route exact path="/error" component={ServerError} />
              </Switch>
            </Layout>
          </Layout.Content>

          <Layout.Footer className="footer">
            <ul>
              <li>
                <a href="mailto:contact.pvce@unsw.edu.au">Contact us</a>
              </li>
              <li>
                <a href="https://www.unsw.edu.au/privacy">Privacy Policy</a>
              </li>
              <li>
                <a href="https://www.unsw.edu.au/copyright-disclaimer">
                  Copyright &amp; Disclaimer
                </a>
              </li>
              <li>
                <div>Office of the Pro Vice-Chancellor (Education)</div>
                <div>UNSW Sydney NSW 2052 Australia</div>
                <div>Authorised by: Pro Vice-Chancellor (E), UNSW</div>
                <div style={{ color: "rgba(117, 117, 117, 0.5)" }}>
                  {`Build date: ${preval`
                    const moment = require("moment");
                    module.exports = moment().format("DD/MM/YYYY");
                  `}`}
                </div>
              </li>
            </ul>
          </Layout.Footer>
        </Layout>
      </AppContext.Provider>
    );
  }
}

export default authenticatedApplication({
  landingPage: <EntryPoint />,
  msalConfig: {
    auth: {
      clientId: process.env.REACT_APP_AZURE_APP_ID,
      authority: process.env.REACT_APP_AZURE_AUTHORITY,
      redirectUri: process.env.REACT_APP_FRONTEND_URL
    }
  },
  onAuthSuccess: async (azureIdToken, azureAccessToken) => {
    const headers = {
      "Content-Type": "application/json; charset=utf8",
      Authorization: "Token " + azureIdToken
    };
    const response = await axios.post(
      "auth/login/",
      { accessToken: azureAccessToken },
      { headers }
    );

    const data = response.data;
    axios.defaults.headers.common["Authorization"] = `Bearer ${data.access}`;
    sessionStorage.setItem("isSystemAdmin", data.is_system_admin);
    sessionStorage.setItem("isCourseAdmin", data.is_course_admin);
    sessionStorage.setItem("hasCourse", data.has_course);

    return {
      accessToken: data.access,
      refreshToken: data.refresh,
      extras: {
        isSystemAdmin: data.is_system_admin,
        isCourseAdmin: data.is_course_admin,
        hasCourse: data.has_course
      }
    };
  },
  onAuthError: error => {
    const { errorCode } = error;

    if (errorCode === "user_cancelled" || errorCode === "access_denied")
      return { type: "warning", message: "Login popup was closed." };
    else if (errorCode === "login_progress_error")
      return { type: "warning", message: "Login popup is already open." };
    else if (errorCode === "popup_window_error")
      return {
        type: "warning",
        message:
          "Error opening popup window. This can happen if you are using IE or if popups are blocked in the browser."
      };
    else if (error.message === "Network Error")
      return {
        type: "error",
        message: (
          <>
            Failed to communicate with the server. If the issue persists, please{" "}
            <a href="mailto:contact.pvce@unsw.edu.au">contact support</a>.
          </>
        )
      };

    const payload = {
      userAgent: window.navigator.userAgent,
      name: error.name,
      code: errorCode,
      message: error.message,
      stack: error.stack.toString().split("\n")
    };
    const headers = {
      "Content-Type": "application/json; charset=utf8",
      common: { Authorization: null }
    };
    axios.post("auth/error/", payload, { headers });

    return {
      type: "error",
      message: (
        <>
          An issue occurred while logging you in. Please try again, ensuring
          that you use <strong>{`<Your zID>`}@ad.unsw.edu.au</strong> to log in.
          If the issue persists, please{" "}
          <a href="mailto:contact.pvce@unsw.edu.au">contact support</a>.
        </>
      )
    };
  },
  refreshAccess: async refresh => {
    const response = await axios.post("auth/refresh/", { refresh });

    const data = response.data;
    axios.defaults.headers.common["Authorization"] = `Bearer ${data.access}`;

    return data.access;
  },
  tokenCheckFrequency: 2
})(withRouter(props => <App {...props} />));
