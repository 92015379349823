import React, { useState } from "react";
import { notification, Button, Tooltip } from "antd";
import axios from "axios";
import _ from "lodash";

const iconMap = {
  default: {
    true: "check",
    false: "close",
  }, 
  lock: {
    true: "lock",
    false: "unlock"
  }
}

const typeMap = {
  default: {
    true: "default",
    false: "danger"
  },
  lock: {
    true: "danger",
    false: "default"
  }
}

const colourMap = {
  default: {
    true: "#43A047"
  }
};

const BooleanField = ({
  courseId,
  classId,
  field,
  value,
  tooltip,
  updateCourse,
  readOnly,
  icons = "default"
}) => {
  const [loading, setLoading] = useState(false);

  const updateBoolean = async () => {
    setLoading(true);
    try {
      if (field === "evaluate") {
        const response = await axios.post(`course/${courseId}/survey_status/`, {
          classes: [classId],
          value: !value
        });
        updateCourse({ wholeObject: true, courseId, value: response.data });
      } else {
        await axios.post(`course/locked_status/`, {
          courses: [courseId],
          value: !value
        });
        updateCourse({ courseId, field: "is_locked", value: !value });
      }
    } catch (error) {
      notification["error"]({ message: error.response.data });
    }
    setLoading(false);
  };

  return (
    <Tooltip title={tooltip[value]}>
      <Button
        className={`editable-table-button ${readOnly ? "disabled" : ""}`}
        disabled={readOnly}
        type={typeMap[icons][value]}
        shape="circle"
        icon={iconMap[icons][value]}
        size="small"
        onClick={updateBoolean}
        loading={loading}
        style={{ color: _.get(colourMap, `${icons}.${value}`) }}
      />
    </Tooltip>
  );
};

export default BooleanField;
