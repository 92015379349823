import React, { useState, useRef } from "react";
import { notification, Select, Icon } from "antd";
import axios from "axios";
import _ from "lodash";

const BulkStaffMembers = ({ onChange, placeholder }) => {
  const [fetching, setFetching] = useState(false);
  const [staff, setStaff] = useState([]);
  const select = useRef();

  const findStaffMember = _.debounce(async filter => {
    if (!filter) return;

    try {
      setFetching(true);
      const query = await axios.get(`staff/?filter=${filter}`);
      setStaff(query.data.staff);
      if (query.data.staff.length === 0)
        notification["warning"]({
          message: "No staff were found with the provided name or zID"
        });
    } catch (error) {
      notification["error"]({ message: error.response.data });
    }
    setFetching(false);
  }, 500);

  return (
    <Select
      mode="multiple"
      defaultActiveFirstOption={false}
      style={{ width: "100%" }}
      ref={select}
      onChange={onChange}
      onSearch={findStaffMember}
      showSearch
      filterOption={false}
      dropdownMatchSelectWidth={false}
      onDropdownVisibleChange={open => {
        if (!open) setStaff([]);
      }}
      notFoundContent={
        fetching ? (
          <Icon type="loading" style={{ color: "#3498db" }} />
        ) : (
          "Search for staff by name or zID"
        )
      }
      placeholder={placeholder}
    >
      {staff.map(staffMember => (
        <Select.Option key={staffMember.zid}>
          {staffMember.display_name} ({staffMember.zid})
        </Select.Option>
      ))}
    </Select>
  );
};

export default BulkStaffMembers;
