import React, { useState, useRef } from "react";
import { notification, Select, Icon } from "antd";
import axios from "axios";
import _ from "lodash";

const StaffMembers = ({
  courseId,
  classId,
  field,
  staffMembers,
  updateCourse
}) => {
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [staff, setStaff] = useState([]);
  const select = useRef();

  const updateStaffMember = async newValue => {
    setLoading(true);
    try {
      let response;
      if (field === "convenors") {
        response = await axios.post(`course/convenors/`, {
          courses: [courseId],
          value: newValue
        });
      } else {
        response = await axios.post(`course/${courseId}/instructors/`, {
          classes: [classId],
          value: newValue
        });
      }

      updateCourse({
        courseId,
        classId,
        field,
        value: response.data
      });
    } catch (error) {
      notification["error"]({ message: error.response.data });
    }
    setLoading(false);
    select.current.blur();
  };

  const findStaffMember = _.debounce(async filter => {
    if (!filter) return;

    try {
      setFetching(true);
      const query = await axios.get(`staff/?filter=${filter}`);
      setStaff(query.data.staff);
      if (query.data.staff.length === 0)
        notification["warning"]({
          message: "No staff were found with the provided name or zID"
        });
    } catch (error) {
      notification["error"]({ message: error.response.data });
    }
    setFetching(false);
  }, 500);

  return (
    <Select
      mode="multiple"
      defaultActiveFirstOption={false}
      style={{ width: "100%" }}
      className="editable-table-field"
      value={staffMembers.map(staffMember => staffMember.zid)}
      ref={select}
      onChange={updateStaffMember}
      onSearch={findStaffMember}
      loading={loading}
      showSearch
      filterOption={false}
      dropdownMatchSelectWidth={false}
      onDropdownVisibleChange={open => {
        if (!open) setStaff([]);
      }}
      suffixIcon={
        <Icon type="loading" style={{ display: loading ? "block" : "none" }} />
      }
      placeholder={`Select ${field}`}
      notFoundContent={
        fetching ? (
          <Icon type="loading" style={{ color: "#3498db" }} />
        ) : (
          "Search for staff by name or zID"
        )
      }
    >
      {staff.length > 0
        ? staff.map(staffMember => (
            <Select.Option key={staffMember.zid}>
              {staffMember.display_name} ({staffMember.zid})
            </Select.Option>
          ))
        : staffMembers.map(staffMember => (
            <Select.Option key={staffMember.zid}>
              {staffMember.display_name} ({staffMember.zid})
            </Select.Option>
          ))}
    </Select>
  );
};

export default StaffMembers;
