import React, { useState, useRef } from "react";
import { Icon, notification, DatePicker } from "antd";
import axios from "axios";
import moment from "moment";

const DateField = ({
  courseId,
  classId,
  field,
  value,
  updateCourse,
  readOnly
}) => {
  const [loading, setLoading] = useState(false);
  const datePicker = useRef();

  const updateDate = async newValue => {
    setLoading(true);
    try {
      const response = await axios.post(`course/${courseId}/survey_date/`, {
        classes: [classId],
        field,
        value: newValue.format("YYYY-MM-DD")
      });

      updateCourse({
        wholeObject: true,
        courseId,
        value: response.data
      });
    } catch (error) {
      notification["error"]({ message: error.response.data });
    }
    setLoading(false);
    datePicker.current.blur();
  };

  if (readOnly) return value;

  return (
    <DatePicker
      value={moment(value)}
      ref={datePicker}
      className="editable-table-field"
      allowClear={false}
      suffixIcon={
        <Icon type="loading" style={{ display: loading ? "block" : "none" }} />
      }
      onChange={updateDate}
    />
  );
};

export default DateField;
