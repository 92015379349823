import React, { Component } from "react";
import {
  Spin,
  Icon,
  Select,
  Table,
  Button,
  Divider,
  Dropdown,
  Menu,
  notification,
  Modal,
  DatePicker,
  Collapse,
  Input,
  Form,
  Radio,
  TreeSelect,
  Col,
  Row
} from "antd";
import axios from "axios";
import moment from "moment";
import _ from "lodash";

import AppContext from "../AppContext";

import {
  DateField,
  BooleanField,
  SurveyType,
  StaffMembers,
  BulkStaffMembers,
  Finalised
} from "./fields";
import MoodleConnection from "./MoodleConnection";

class ClassTable extends Component {
  static contextType = AppContext;

  state = { selectedRowKeys: [] };

  render() {
    const {
      course,
      updateCourse,
      isAdminView,
      handleBulkEdit,
      loading
    } = this.props;
    const { selectedRowKeys } = this.state;

    const columns = [
      {
        title: "Name",
        key: "name",
        dataIndex: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
        defaultSortOrder: "ascend"
      },
      {
        title: "Component",
        key: "component",
        dataIndex: "component",
        sorter: (a, b) => a.component.localeCompare(b.component)
      },
      {
        title: "Section",
        key: "class_section",
        dataIndex: "class_section",
        sorter: (a, b) => a.class_section.localeCompare(b.class_section)
      },
      {
        title: "Instructors",
        key: "instructors",
        dataIndex: "instructors",
        render: (instructors, section) => (
          <StaffMembers
            courseId={course.id}
            classId={section.id}
            field="instructors"
            staffMembers={instructors}
            updateCourse={updateCourse}
          />
        ),
        onCell: () => ({ style: { minWidth: 250 } })
      }
    ];

    if (isAdminView)
      columns.push(
        {
          title: "Surveyed",
          key: "evaluate",
          dataIndex: "evaluate",
          render: (evaluate, section) => {
            const identifier = `${course.code}-${section.component}-${section.class_section}`;
            return (
              <BooleanField
                courseId={course.id}
                classId={section.id}
                field="evaluate"
                value={evaluate}
                tooltip={{
                  true: `${identifier} will be surveyed`,
                  false: `${identifier} will not be surveyed`
                }}
                updateCourse={updateCourse}
              />
            );
          }
        },
        {
          title: "Survey Type",
          key: "survey_type",
          dataIndex: "survey_type",
          render: (surveyType, section) => (
            <SurveyType
              courseId={course.id}
              classId={section.id}
              value={surveyType}
              updateCourse={updateCourse}
            />
          )
        },
        {
          title: "Survey Start",
          key: "evaluation_start",
          dataIndex: "evaluation_start",
          width: 150,
          sorter: (a, b) =>
            moment(a.evaluation_start).valueOf() -
            moment(b.evaluation_start).valueOf(),
          render: (evaluationStart, section) => (
            <DateField
              courseId={course.id}
              classId={section.id}
              field="evaluation_start"
              value={evaluationStart}
              updateCourse={updateCourse}
            />
          )
        },
        {
          title: "Survey End",
          key: "evaluation_end",
          dataIndex: "evaluation_end",
          width: 150,
          sorter: (a, b) =>
            moment(a.evaluation_end).valueOf() -
            moment(b.evaluation_end).valueOf(),
          render: (evaluationEnd, section) => (
            <DateField
              courseId={course.id}
              classId={section.id}
              field="evaluation_end"
              value={evaluationEnd}
              updateCourse={updateCourse}
            />
          )
        }
      );

    return (
      <>
        {selectedRowKeys.length > 0 && (
          <>
            <Button
              disabled={!selectedRowKeys.length}
              icon="close"
              onClick={() =>
                this.setState({
                  selectedRowKeys: []
                })
              }
            >
              Clear selection
            </Button>

            {isAdminView ? (
              <Dropdown
                overlay={
                  <Menu
                    onClick={key =>
                      handleBulkEdit(key, selectedRowKeys, course.id)
                    }
                  >
                    <Menu.Item key="instructors">Change instructors</Menu.Item>
                    <Menu.Item key="is_surveyed">Mark as surveyed</Menu.Item>
                    <Menu.Item key="is_not_surveyed">
                      Mark as not surveyed
                    </Menu.Item>
                    <Menu.Item key="survey_type">Change survey type</Menu.Item>
                    <Menu.Item key="evaluation_start">
                      Change survey start
                    </Menu.Item>
                    <Menu.Item key="evaluation_end">
                      Change survey end
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button
                  style={{ marginLeft: 5 }}
                  loading={loading}
                  type="primary"
                >
                  Bulk edit
                </Button>
              </Dropdown>
            ) : (
              <Button
                style={{ marginLeft: 5 }}
                type="primary"
                onClick={() =>
                  handleBulkEdit(
                    { key: "instructors" },
                    selectedRowKeys,
                    course.id
                  )
                }
                loading={loading}
              >
                Bulk update instructors
              </Button>
            )}

            <span style={{ marginLeft: 10 }}>
              Selected {selectedRowKeys.length} classes
            </span>
          </>
        )}

        <Table
          style={{ marginTop: 10 }}
          size="small"
          bordered
          columns={columns}
          locale={{
            emptyText: "No classes match the specified criteria"
          }}
          dataSource={course.class_sections}
          rowKey={classSection => classSection.id}
          pagination={false}
          rowSelection={
            !course.is_finalised
              ? {
                  selectedRowKeys,
                  onChange: selectedRowKeys =>
                    this.setState({ selectedRowKeys })
                }
              : null
          }
        />
      </>
    );
  }
}

class CourseAdministration extends Component {
  static contextType = AppContext;

  state = {
    loading: true,
    terms: [],
    currentTerm: sessionStorage.getItem("currentTerm"),
    courses: [],
    pagination: {
      pageSize: 10,
      current: 1,
      showSizeChanger: true,
      pageSizeOptions: ["10", "25", "50"]
    },
    expandedRowKeys: [],
    selectedRowKeys: [],
    bulkEditModal: { visible: false },
    filters: {},
    moodleConnection: { visible: false }
  };

  async componentWillMount() {
    const { history } = this.props;
    const { pagination } = this.state;

    try {
      const availableTerms = await axios.get(`course/available_terms/`);
      const terms = availableTerms.data;
      const currentTerm = this.state.currentTerm || terms[0].code;

      const termFaculties = await axios.get(
        `course/term/${currentTerm}/faculties/`
      );
      const faculties = termFaculties.data;

      this.setState(
        {
          terms,
          currentTerm,
          faculties: this.generateFacultyOptions(faculties),
          loading: false
        },
        () => this.getCourses({ pagination })
      );
    } catch (error) {
      history.push("/error");
    }
  }

  generateFacultyOptions = faculties =>
    faculties.map(faculty => ({
      title: faculty.code,
      value: `faculty_${faculty.code}`,
      key: `faculty_${faculty.code}`,
      children: faculty.schools.map(school => ({
        title: school.code,
        value: `school_${school.code}`,
        key: `school_${school.code}`
      }))
    }));

  getCourses = async ({ pagination, sorter }) => {
    const { history, location } = this.props;
    const { currentTerm, filters } = this.state;

    const payload = {
      pageSize: pagination.pageSize,
      filters
    };

    const isAdminView = location.pathname === "/admin";

    if (sorter && sorter.field)
      payload.orderBy = [
        sorter.order === "descend" ? "-" : "",
        sorter.field
      ].join("");

    this.setState({ fetching: true });

    try {
      const termCourses = await axios.post(
        `course/term/${currentTerm}/?view=${
          isAdminView ? "courseadmin" : "personal"
        }&page=${pagination.current}`,
        payload
      );
      const { results: courses, count: total } = termCourses.data;
      this.setState({
        courses,
        fetching: false,
        pagination: { ...pagination, total },
        sorter,
        selectedRowKeys: [],
        expandedRowKeys: isAdminView ? [] : courses.map(course => course.id)
      });
    } catch (error) {
      if (error.response.status === 403) {
        history.push("/forbidden");
      } else {
        history.push("/error");
      }
    }
  };

  updateCourse = ({ courseId, classId, field, value, wholeObject }) => {
    const { courses } = this.state;

    if (wholeObject) {
      const courseIndex = courses.findIndex(course => course.id === courseId);
      courses[courseIndex] = value;
    } else {
      const course = courses.find(course => course.id === courseId);

      if (classId) {
        const classSection = course.class_sections.find(
          classSection => classSection.id === classId
        );
        classSection[field] = value;
      } else {
        course[field] = value;
      }
    }

    this.setState({ courses });
  };

  bulkUpdateCourse = ({ courseId, courseIds, classIds, field, value }) => {
    const { courses } = this.state;

    if (classIds) {
      const course = courses.find(course => course.id === courseId);
      classIds.forEach(classId => {
        const classSection = course.class_sections.find(
          classSection => classSection.id === classId
        );
        classSection[field] = value;
      });
    } else {
      courseIds.forEach(courseId => {
        const course = courses.find(course => course.id === courseId);
        course[field] = value;
      });
    }

    this.setState({ courses });
  };

  onTableChange = (pagination, filters, sorter) => {
    // NOTE: filters from Ant Design table component are not used
    // Filters are instead defined in the collapse panel
    if (
      this.state.sorter &&
      sorter &&
      (_.get(this.state.sorter, "field") !== _.get(sorter, "field") ||
        _.get(this.state.sorter, "order") !== _.get(sorter, "order"))
    )
      pagination.current = 1;

    this.getCourses({ pagination, sorter });
  };

  onExportStaffPictures = async () => {
    this.setState({ exportStaffPicLoading: true });
    try {
      const response = await axios.post(`admin/export_pictures/`, {
        headers: { accept: "text/csv" }
      });

      let a = window.document.createElement("a");
      a.href = window.URL.createObjectURL(new Blob([response.data]), {
        type: response.headers["content-type"]
      });
      const fileName = "staff_pictures.csv";
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      notification["error"]({ message: "An error occured during export" });
    }
    this.setState({ exportStaffPicLoading: false });
  };

  onExport = async () => {
    const { selectedRowKeys, currentTerm, filters } = this.state;

    this.setState({ exportLoading: true });
    try {
      const response = await axios.post(
        `course/term/${currentTerm}/export/`,
        {
          selected: selectedRowKeys,
          filters: filters
        },
        { headers: { accept: "application/zip" }, responseType: "arraybuffer" }
      );

      let a = window.document.createElement("a");
      a.href = window.URL.createObjectURL(new Blob([response.data]), {
        type: response.headers["content-type"]
      });
      const fileName = "myprofile_data.zip";
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      notification["error"]({ message: "An error occured during export" });
    }
    this.setState({ exportLoading: false });
  };

  handleBulkEdit = async ({ key }, classes, course) => {
    const { selectedRowKeys } = this.state;
    const { isSystemAdmin } = this.context;

    if (["is_surveyed", "is_not_surveyed"].includes(key)) {
      this.setState({ bulkEditing: true });
      try {
        const response = await axios.post(`course/${course}/survey_status/`, {
          classes,
          value: ["is_surveyed"].includes(key)
        });
        this.updateCourse({
          wholeObject: true,
          courseId: course,
          value: response.data
        });
      } catch (error) {
        notification["error"]({ message: error.response.data });
      }
      this.setState({ bulkEditing: false });
    }

    if (key === "finalise") {
      try {
        Modal.confirm({
          title: "Confirm finalisation",
          content: `Are you sure you want to finalise these courses? If you proceed, ${
            isSystemAdmin
              ? "faculty staff will no longer be able to edit details about these courses."
              : "you will be unable to edit the details of these courses unless an administrator reverts the finalisation."
          }`,
          okText: "Proceed with finalisation",
          okType: "danger",
          cancelText: "Cancel",
          onOk: async () => {
            this.setState({ bulkEditing: true });
            await axios.post(`course/finalise/`, {
              courses: selectedRowKeys,
              value: true
            });
            this.bulkUpdateCourse({
              courseIds: selectedRowKeys,
              field: "is_finalised",
              value: true
            });
            this.setState({
              bulkEditing: false,
              selectedRowKeys: []
            });
          }
        });
      } catch (error) {
        notification["error"]({ message: error.response.data });
      }
    }

    if (["evaluation_start", "evaluation_end"].includes(key)) {
      const labelMap = {
        evaluation_start: "survey start",
        evaluation_end: "survey end"
      };
      this.setState({
        bulkEditModal: {
          visible: true,
          title: `Bulk change ${labelMap[key]} date`,
          content: (
            <>
              <div style={{ marginBottom: 10 }}>
                Specify a {labelMap[key]} date below:
              </div>
              <DatePicker
                style={{ width: "100%" }}
                onChange={value =>
                  this.setState({
                    bulkEditModal: { ...this.state.bulkEditModal, value }
                  })
                }
              />
            </>
          ),
          onOk: async () => {
            const value = this.state.bulkEditModal.value
              ? this.state.bulkEditModal.value.format("YYYY-MM-DD")
              : null;

            if (!value) {
              this.setState({
                bulkEditModal: { visible: false }
              });
              return;
            }

            this.setState({ bulkEditing: true });

            try {
              this.setState({ bulkEditModal: { visible: false } });
              const response = await axios.post(
                `course/${course}/survey_date/`,
                {
                  classes,
                  field: key,
                  value
                }
              );
              this.updateCourse({
                wholeObject: true,
                courseId: course,
                value: response.data
              });
            } catch (error) {
              notification["error"]({ message: error.response.data });
            }
            this.setState({
              bulkEditing: false
            });
          }
        }
      });
    }

    if (key === "survey_type") {
      this.setState({
        bulkEditModal: {
          visible: true,
          title: `Bulk change survey type`,
          content: (
            <>
              <div style={{ marginBottom: 10 }}>
                Specify a survey type below:
              </div>
              <Select
                style={{ width: "100%" }}
                onChange={value =>
                  this.setState({
                    bulkEditModal: { ...this.state.bulkEditModal, value }
                  })
                }
              >
                <Select.Option value="Course Questions">
                  Course Questions
                </Select.Option>
                <Select.Option value="Teaching Questions">
                  Teaching Questions
                </Select.Option>
                <Select.Option value="Course and Teaching Questions">
                  Course and Teaching Questions
                </Select.Option>
              </Select>
            </>
          ),
          onOk: async () => {
            const value = this.state.bulkEditModal.value;

            if (!value) {
              this.setState({
                bulkEditModal: { visible: false }
              });
              return;
            }

            this.setState({ bulkEditing: true });

            try {
              this.setState({ bulkEditModal: { visible: false } });
              const response = await axios.post(
                `course/${course}/survey_type/`,
                {
                  classes,
                  value
                }
              );
              this.updateCourse({
                wholeObject: true,
                courseId: course,
                value: response.data
              });
            } catch (error) {
              notification["error"]({ message: error.response.data });
            }
            this.setState({
              bulkEditing: false
            });
          }
        }
      });
    }

    if (key === "convenors") {
      this.setState({
        bulkEditModal: {
          visible: true,
          title: `Bulk change ${key}`,
          content: (
            <>
              <div style={{ marginBottom: 10 }}>Specify the {key} below:</div>
              <BulkStaffMembers
                onChange={value => {
                  this.setState({
                    bulkEditModal: {
                      ...this.state.bulkEditModal,
                      value
                    }
                  });
                }}
              />
            </>
          ),
          onOk: async () => {
            this.setState({ bulkEditing: true });
            try {
              this.setState({ bulkEditModal: { visible: false } });
              const response = await axios.post(`course/convenors/`, {
                courses: selectedRowKeys,
                classes,
                field: key,
                value: this.state.bulkEditModal.value || []
              });
              this.bulkUpdateCourse({
                courseIds: selectedRowKeys,
                courseId: course,
                classIds: classes,
                field: key,
                value: response.data
              });
            } catch (error) {
              notification["error"]({ message: error.response.data });
            }
            this.setState({
              bulkEditing: false
            });
          }
        }
      });
    }

    if (key === "instructors") {
      this.setState({
        bulkEditModal: {
          visible: true,
          title: `Bulk change ${key}`,
          content: (
            <>
              <div style={{ marginBottom: 10 }}>Specify the {key} below:</div>
              <BulkStaffMembers
                onChange={value => {
                  this.setState({
                    bulkEditModal: {
                      ...this.state.bulkEditModal,
                      value
                    }
                  });
                }}
              />
            </>
          ),
          onOk: async () => {
            this.setState({ bulkEditing: true });
            try {
              this.setState({ bulkEditModal: { visible: false } });
              const response = await axios.post(
                `course/${course}/instructors/`,
                {
                  classes,
                  field: key,
                  value: this.state.bulkEditModal.value || []
                }
              );
              this.bulkUpdateCourse({
                courseIds: selectedRowKeys,
                courseId: course,
                classIds: classes,
                field: key,
                value: response.data
              });
            } catch (error) {
              notification["error"]({ message: error.response.data });
            }
            this.setState({
              bulkEditing: false
            });
          }
        }
      });
    }
  };

  onFilter = () => {
    const { form } = this.props;
    const { pagination, sorter } = this.state;

    form.validateFields((err, filters) => {
      pagination.current = 1;
      this.setState({ filters, pagination }, () =>
        this.getCourses({ pagination, sorter })
      );
    });
  };

  render() {
    const { location, form } = this.props;
    const {
      loading,
      terms,
      faculties,
      currentTerm,
      fetching,
      courses,
      pagination,
      sorter,
      expandedRowKeys,
      selectedRowKeys,
      bulkEditing,
      bulkEditModal,
      filters,
      moodleConnection
    } = this.state;
    const { isSystemAdmin } = this.context;
    const { getFieldDecorator } = form;

    const isAdminView = location.pathname === "/admin";
    const hasFilters = Object.values(filters).some(
      value => value !== null && value !== undefined
    );

    const columns = [
      {
        title: "Code",
        key: "code",
        dataIndex: "code",
        sorter: (a, b) => a.code.localeCompare(b.code),
        sortOrder: sorter ? sorter.field === "code" && sorter.order : "ascend",
        render: (code, course) => (
          <>
            <span style={{ marginRight: 10 }}>{code}</span>

            {isAdminView ? (
              <a
                href={`/?course=${course.key}&view=lti`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginLeft: 6 }}
              >
                <Icon type="link" />
              </a>
            ) : (
              <Button
                icon="thunderbolt"
                onClick={() =>
                  this.setState({
                    moodleConnection: { visible: true, courseKey: course.key }
                  })
                }
                size="small"
                style={{ marginTop: 5 }}
              >
                Connect to Moodle
              </Button>
            )}
          </>
        )
      },
      {
        title: "Name",
        key: "name",
        dataIndex: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortOrder: sorter && sorter.field === "name" && sorter.order
      },
      {
        title: "School",
        key: "school",
        dataIndex: "school",
        sorter: (a, b) => a.school.localeCompare(b.school)
      },
      {
        title: "Faculty",
        key: "faculty",
        dataIndex: "faculty",
        sorter: (a, b) => a.faculty.localeCompare(b.faculty)
      },
      {
        title: "Finalised",
        key: "is_finalised",
        dataIndex: "is_finalised",
        render: (isFinalised, course) => (
          <Finalised
            readOnly={!isAdminView}
            courseId={course.id}
            value={isFinalised}
            tooltip={{
              true: `${course.code} is finalised`,
              false: `${course.code} is not finalised`
            }}
            isSystemAdmin={isSystemAdmin}
            updateCourse={update => {
              this.setState({ selectedRowKeys: [] });
              this.updateCourse(update);
            }}
          />
        )
      }
    ];

    if (isAdminView)
      columns.splice(-1, 0, {
        title: "Convenors",
        key: "convenors",
        dataIndex: "convenors",
        render: (convenors, course) => (
          <StaffMembers
            courseId={course.id}
            field="convenors"
            staffMembers={convenors}
            updateCourse={this.updateCourse}
          />
        ),
        onCell: () => ({ style: { minWidth: 250 } })
      });

    if (isSystemAdmin) {
      columns.splice(
        -1,
        0,
        ...[
          {
            title: "Standard Survey",
            key: "is_standard_survey",
            dataIndex: "is_standard_survey",
            render: (isStandardSurvey, course) => (
              <BooleanField
                readOnly
                courseId={course.id}
                value={isStandardSurvey}
                tooltip={{
                  true: `Classes in ${course.code} (that are marked as being surveyed) all have the same survey period`,
                  false: `Classes in ${course.code} (that are marked as being surveyed) do not all have the same survey period`
                }}
              />
            )
          },
          {
            title: "Locked",
            key: "is_locked",
            dataIndex: "is_locked",
            render: (isLocked, course) => (
              <BooleanField
                icons="lock"
                courseId={course.id}
                field="is_locked"
                value={isLocked}
                tooltip={{
                  true: `Course ${course.code} is locked - data will not be overridden by an upload`,
                  false: `Course ${course.code} is unlocked - data will be overridden by an upload`
                }}
                updateCourse={this.updateCourse}
              />
            )
          }
        ]
      );
    }

    return (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            maxWidth: 275,
            marginBottom: 15
          }}
        >
          <div style={{ marginRight: 10 }}>Term:</div>
          <div style={{ flex: 1 }}>
            <Select
              style={{ width: "100%" }}
              disabled={loading}
              loading={loading}
              placeholder="Loading available terms..."
              value={loading ? undefined : currentTerm.toString()}
              onChange={currentTerm => {
                this.setState({ currentTerm }, async () => {
                  this.getCourses({ pagination, sorter });

                  const termFaculties = await axios.get(
                    `course/term/${currentTerm}/faculties/`
                  );
                  const faculties = termFaculties.data;
                  this.setState({
                    faculties: this.generateFacultyOptions(faculties)
                  });
                });
                sessionStorage.setItem("currentTerm", currentTerm);
              }}
            >
              {terms.map(term => (
                <Select.Option key={term.code}>
                  {term.label} ({term.code})
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>

        {currentTerm && !loading && (
          <>
            <Collapse bordered={false} defaultActiveKey={[]}>
              <Collapse.Panel
                header={
                  <div>
                    Course filter (
                    {hasFilters ? (
                      <span style={{ color: "#1890ff" }}>filter active</span>
                    ) : (
                      "no filter active"
                    )}
                    )
                  </div>
                }
                key="filter"
                style={{ marginBottom: 12 }}
              >
                <Form
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  style={{
                    maxWidth: 800,
                    background: "#FAFAFA",
                    padding: 16,
                    borderRadius: 4,
                    border: "1px solid #EEE"
                  }}
                >
                  <Row gutter={8}>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label="Course code"
                        style={{ marginBottom: 8 }}
                      >
                        {getFieldDecorator("code")(
                          <Input placeholder="Enter course code" />
                        )}
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item
                        label="Course name"
                        style={{ marginBottom: 8 }}
                      >
                        {getFieldDecorator("name")(
                          <Input placeholder="Enter course name" />
                        )}
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item
                        label="Staff members"
                        style={{ marginBottom: 8 }}
                      >
                        {getFieldDecorator("staff")(
                          <>
                            <BulkStaffMembers
                              placeholder="Enter staff members"
                              onChange={staff => form.setFieldsValue({ staff })}
                            />
                          </>
                        )}
                      </Form.Item>
                    </Col>

                    {isAdminView && (
                      <Col xs={24} md={12}>
                        <Form.Item
                          label="Standard survey"
                          style={{ marginBottom: 8 }}
                        >
                          {getFieldDecorator("standard_survey", {
                            initialValue: undefined
                          })(
                            <Radio.Group>
                              <Radio.Button value={undefined}>Any</Radio.Button>
                              <Radio.Button value={false}>No</Radio.Button>
                              <Radio.Button value={true}>Yes</Radio.Button>
                            </Radio.Group>
                          )}
                        </Form.Item>
                      </Col>
                    )}

                    <Col xs={24} md={12}>
                      <Form.Item
                        label="Faculty/School"
                        style={{ marginBottom: 8 }}
                      >
                        {getFieldDecorator("faculty_school")(
                          <TreeSelect
                            treeCheckable
                            showCheckedStrategy="SHOW_PARENT"
                            searchPlaceholder="Enter faculties and/or schools"
                            treeData={faculties}
                          />
                        )}
                      </Form.Item>
                    </Col>

                    {isSystemAdmin && (
                      <Col xs={24} md={12}>
                        <Form.Item label="Locked" style={{ marginBottom: 8 }}>
                          {getFieldDecorator("is_locked", {
                            initialValue: undefined
                          })(
                            <Radio.Group>
                              <Radio.Button value={undefined}>Any</Radio.Button>
                              <Radio.Button value={false}>No</Radio.Button>
                              <Radio.Button value={true}>Yes</Radio.Button>
                            </Radio.Group>
                          )}
                        </Form.Item>
                      </Col>
                    )}

                    <Col xs={24} md={12}>
                      <Form.Item label="Finalised" style={{ marginBottom: 8 }}>
                        {getFieldDecorator("finalised", {
                          initialValue: undefined
                        })(
                          <Radio.Group>
                            <Radio.Button value={undefined}>Any</Radio.Button>
                            <Radio.Button value={false}>No</Radio.Button>
                            <Radio.Button value={true}>Yes</Radio.Button>
                          </Radio.Group>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>

                <div style={{ marginTop: 12 }}>
                  <Button
                    icon="dash"
                    type="danger"
                    style={{ marginRight: 5 }}
                    onClick={() => {
                      form.resetFields();
                      if (hasFilters)
                        this.setState({ filters: {} }, () =>
                          this.getCourses({ pagination, sorter })
                        );
                    }}
                  >
                    Reset filter
                  </Button>
                  <Button icon="filter" type="primary" onClick={this.onFilter}>
                    Apply filter
                  </Button>
                </div>
              </Collapse.Panel>
            </Collapse>

            <div style={{ marginBottom: 10 }}>
              <Button
                style={{ marginRight: 5 }}
                icon="plus"
                onClick={() =>
                  this.setState({
                    expandedRowKeys: courses.map(course => course.id)
                  })
                }
              >
                Expand all
              </Button>

              <Button
                icon="minus"
                onClick={() =>
                  this.setState({
                    expandedRowKeys: []
                  })
                }
              >
                Collapse all
              </Button>

              <Button
                icon="export"
                style={{ float: "right" }}
                onClick={this.onExport}
                loading={this.state.exportLoading}
              >
                {selectedRowKeys.length > 0 ? "Export selected" : "Export data"}
              </Button>

              {isSystemAdmin && (
                <Button
                  icon="picture"
                  style={{ float: "right", marginRight: 5 }}
                  onClick={this.onExportStaffPictures}
                  loading={this.state.exportStaffPicLoading}
                >
                  Export staff pictures
                </Button>
              )}

              {isAdminView && selectedRowKeys.length > 0 && (
                <>
                  <Divider type="vertical" />

                  <Button
                    disabled={!selectedRowKeys.length}
                    icon="close"
                    onClick={() =>
                      this.setState({
                        selectedRowKeys: []
                      })
                    }
                  >
                    Clear selection
                  </Button>

                  <Dropdown
                    overlay={
                      <Menu onClick={this.handleBulkEdit}>
                        <Menu.Item key="convenors">Change convenors</Menu.Item>
                        <Menu.Item key="finalise">Finalise</Menu.Item>
                      </Menu>
                    }
                  >
                    <Button
                      style={{ marginLeft: 5 }}
                      disabled={bulkEditing}
                      type="primary"
                    >
                      <Icon type={bulkEditing ? "loading" : "edit"} /> Bulk edit
                    </Button>
                  </Dropdown>

                  <span style={{ marginLeft: 10 }}>
                    Selected {selectedRowKeys.length} courses
                  </span>
                </>
              )}
            </div>

            <Table
              className="course-table"
              size="small"
              columns={columns}
              loading={
                fetching && {
                  size: "large",
                  tip: "Loading courses...",
                  indicator: (
                    <Spin size="large" indicator={<Icon type="loading" />} />
                  )
                }
              }
              locale={{
                emptyText: "No courses match the specified criteria"
              }}
              dataSource={courses}
              rowKey={course => course.id}
              pagination={pagination}
              onChange={this.onTableChange}
              expandedRowKeys={expandedRowKeys}
              expandedRowRender={course => (
                <ClassTable
                  course={course}
                  updateCourse={this.updateCourse}
                  isAdminView={isAdminView}
                  handleBulkEdit={this.handleBulkEdit}
                  loading={bulkEditing}
                />
              )}
              onExpandedRowsChange={expandedRowKeys =>
                this.setState({ expandedRowKeys })
              }
              rowSelection={
                isAdminView
                  ? {
                      selectedRowKeys,
                      getCheckboxProps: course => ({
                        disabled: course.is_finalised
                      }),
                      onChange: (selectedRowKeys, selectedRows) =>
                        this.setState({
                          selectedRowKeys: selectedRows
                            .filter(course => !course.is_finalised)
                            .map(course => course.id)
                        })
                    }
                  : null
              }
            />

            <Modal
              title={bulkEditModal.title}
              visible={bulkEditModal.visible}
              onOk={bulkEditModal.onOk}
              onCancel={() =>
                this.setState({ bulkEditModal: { visible: false } })
              }
            >
              {bulkEditModal.content}
            </Modal>

            <MoodleConnection
              {...moodleConnection}
              onClose={() =>
                this.setState({ moodleConnection: { visible: false } })
              }
            />
          </>
        )}
      </>
    );
  }
}

export default Form.create()(CourseAdministration);
