import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Icon, Divider, Row, Col } from "antd";
import preval from "preval.macro";
import queryString from "query-string";
import axios from "axios";

import { LandingPage } from "login-landing-page";

import errorImage from "../img/500.svg";
import { StaffCard, EmbeddedStaffCard } from "./StaffCard";

class EntryPoint extends Component {
  state = { showLogin: true };

  async componentWillMount() {
    const { location } = this.props;

    const { course: courseKey, view, show } = queryString.parse(
      location.search
    );

    // Default to the login landing page if there is no "view" querystring
    if (!view) return;

    // If there is a "view" querystring but no "course" querystring, then the
    // backend failed to find a course matched with the LTI resource id
    if (!courseKey) {
      this.setState({
        showLogin: false,
        errorMessage: `This Moodle page has not been connected to the contact details tool. Please check back later.`
      });
      return;
    }

    this.setState({ fetching: true, showLogin: false });
    try {
      const headers = {
        "Content-Type": "application/json; charset=utf8",
        common: { Authorization: null }
      };
      const response = await axios.get(`contacts/${courseKey}/`, { headers });
      const course = response.data;

      this.setState({
        course,
        view,
        show: show ? show : "all",
        fetching: false
      });
    } catch (error) {
      let errorMessage;
      if (error.response.status === 404) {
        errorMessage = "Invalid course contact URL";
      } else {
        errorMessage = `An error occurred while fetching course contacts`;
      }
      this.setState({ fetching: false, errorMessage });
    }
  }

  render() {
    const { handleLogin, loading, error } = this.props;
    const {
      showLogin,
      course,
      view,
      show,
      fetching,
      errorMessage
    } = this.state;

    if (showLogin)
      return (
        <LandingPage
          handleLogin={handleLogin}
          loading={loading}
          error={error}
          title="MyProfile"
          background="https://cdn.teaching.unsw.edu.au/161006_UNSW_016.jpg"
          logo={
            <a href="https://www.unsw.edu.au/">
              <img
                src="https://cdn.teaching.unsw.edu.au/unswbranding/unsw_neg.png"
                alt="UNSW Logo"
              />
            </a>
          }
          footerItems={[
            <a href="mailto:contact.pvce@unsw.edu.au">Contact us</a>,
            <a href="https://www.unsw.edu.au/privacy">Privacy Policy</a>,
            <a href="https://www.unsw.edu.au/copyright-disclaimer">
              Copyright &amp; Disclaimer
            </a>,
            <span style={{ color: "rgba(117, 117, 117, 0.5)" }}>
              {`Build date: ${preval`
                const moment = require("moment");
                module.exports = moment().format("DD/MM/YYYY");
              `}`}
            </span>
          ]}
        />
      );

    if (fetching)
      return (
        <div id="loading">
          <div id="spinner" />
        </div>
      );

    if (view === "embed")
      return (
        <div
          style={{
            background: "transparent",
            fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
            marginRight: 10
          }}
        >
          {errorMessage ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Icon
                type="close-circle"
                style={{ fontSize: "1.3em", color: "#C62828", marginRight: 10 }}
              />
              <span style={{ fontSize: "1.2em" }}>{errorMessage}</span>
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                borderRadius: 2
              }}
            >
              {(show === "all" || show !== "instructor") && (
                <>
                  <h2
                    style={{
                      marginBottom: 2,
                      marginTop: 0,
                      fontWeight: 500,
                      fontSize: 14
                    }}
                  >
                    Convenor{course.convenors.length > 1 ? "s" : ""}
                  </h2>

                  <Row gutter={12}>
                    {!course.convenors.length && (
                      <Col span={24}>
                        <div style={{ marginBottom: 12 }}>
                          No convenors have been associated with this course.
                        </div>
                      </Col>
                    )}
                    {course.convenors.map(convenor => (
                      <Col span={24} key={convenor.email}>
                        <EmbeddedStaffCard convenor staff={convenor} />
                      </Col>
                    ))}
                  </Row>
                </>
              )}
              {(show === "all" || show !== "convenor") && (
                <>
                  <h3
                    style={{
                      marginBottom: 2,
                      marginTop: 0,
                      fontWeight: 500,
                      fontSize: 14
                    }}
                  >
                    Instructor{course.instructors.length > 1 ? "s" : ""}
                  </h3>

                  <Row gutter={16}>
                    {!course.instructors.length && (
                      <Col span={24}>
                        <div>
                          No instructors have been associated with this course.
                        </div>
                      </Col>
                    )}
                    {course.instructors.map(instructor => (
                      <Col span={24} key={instructor.email}>
                        <EmbeddedStaffCard staff={instructor} />
                      </Col>
                    ))}
                  </Row>
                </>
              )}
            </div>
          )}
        </div>
      );
    else
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 25,
            minHeight: "100%"
          }}
        >
          {errorMessage ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                maxWidth: 600,
                textAlign: "center"
              }}
            >
              <img
                src={errorImage}
                alt="Server Error"
                style={{
                  marginBottom: "1em",
                  maxWidth: "100%",
                  height: "auto"
                }}
              />
              <span style={{ fontSize: "2em" }}>{errorMessage}</span>
            </div>
          ) : (
            <div
              style={{
                maxWidth: 1200,
                width: "100%",
                background: "#FFF",
                borderRadius: 4,
                padding: 25,
                border: "1px rgba(0, 0, 0, 0.15) solid"
              }}
            >
              <h1 style={{ textAlign: "center" }}>
                {course.code} Contact Details
              </h1>

              <h2 style={{ marginBottom: 0 }}>
                Convenor{course.convenors.length > 1 ? "s" : ""}
              </h2>
              <Divider style={{ margin: "12px 0" }} />
              <Row gutter={16}>
                {!course.convenors.length && (
                  <Col span={24}>
                    <div style={{ marginBottom: 12 }}>
                      No convenors have been associated with this course.
                    </div>
                  </Col>
                )}
                {course.convenors.map(convenor => (
                  <Col sm={24} md={12} lg={8} key={convenor.email}>
                    <StaffCard staff={convenor} />
                  </Col>
                ))}
              </Row>

              <h2 style={{ marginBottom: 0 }}>
                Instructor{course.instructors.length > 1 ? "s" : ""}
              </h2>
              <Divider style={{ margin: "12px 0" }} />
              <Row gutter={16}>
                {!course.instructors.length && (
                  <Col span={24}>
                    <div>
                      No instructors have been associated with this course.
                    </div>
                  </Col>
                )}
                {course.instructors.map(instructor => (
                  <Col sm={24} md={12} lg={8} key={instructor.email}>
                    <StaffCard staff={instructor} />
                  </Col>
                ))}
              </Row>
            </div>
          )}
        </div>
      );
  }
}

export default withRouter(EntryPoint);
