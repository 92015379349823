import React, { Component } from "react";
import {
  Avatar,
  Select,
  Icon,
  Table,
  Button,
  Alert,
  Form,
  Modal,
  Upload,
  notification,
  Input,
  Checkbox,
  Tooltip
} from "antd";
import axios from "axios";
import _ from "lodash";
import moment from "moment";

import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import AppContext from "../AppContext";

import Timeslot from "./Timeslot";
import MoodleConnection from "./MoodleConnection";

class Profile extends Component {
  static contextType = AppContext;

  state = {
    loading: true,
    terms: [],
    currentTerm: sessionStorage.getItem("currentTerm"),
    crop: { aspect: 1 },
    imageHash: new Date().getTime(), // This is used to force reload of profile picture after updating it,
    contactType: "all",
    moodleConnection: { visible: false }
  };

  async componentWillMount() {
    const { history } = this.props;
    const { currentTerm } = this.state;

    try {
      const profile = await axios.get(`profile/details/`);
      this.setState({
        details: profile.data,
        imageLoading: !!profile.data.picture
      });
    } catch (error) {
      history.push("/error");
    }

    try {
      const availableTerms = await axios.get(`course/available_terms/`);
      const terms = availableTerms.data;
      this.setState(
        {
          terms,
          currentTerm: currentTerm ? currentTerm : terms[0].code,
          loading: false
        },
        () => this.getProfile()
      );
    } catch (error) {
      history.push("/error");
    }
  }

  getProfile = async () => {
    const { history } = this.props;
    const { currentTerm } = this.state;

    try {
      const response = await axios.get(`course/profile/${currentTerm}/`);
      this.setState({
        courses: response.data
      });
    } catch (error) {
      if (error.response.status === 403) {
        history.push("/forbidden");
      } else {
        history.push("/error");
      }
    }
  };

  updateTimeslot = ({ type, courseId, timeslotId, updatedTimeslot }) => {
    const { courses } = this.state;

    const course = courses.find(course => course.id === courseId);

    if (type === "add") {
      course.timeslots.push({ id: _.uniqueId("timeslot_") });
    } else {
      const timeslotIndex = course.timeslots.findIndex(
        timeslot => timeslot.id === timeslotId
      );

      if (type === "delete") {
        course.timeslots.splice(timeslotIndex, 1);
      } else if (type === "update") {
        course.timeslots[timeslotIndex] = updatedTimeslot;
      }
    }

    this.setState({ courses });
  };

  columns = () => {
    return [
      {
        title: "Course",
        key: "code",
        dataIndex: "code",
        sorter: (a, b) => a.code.localeCompare(b.code),
        defaultSortOrder: "ascend",
        width: 150,
        render: (code, course) => (
          <>
            <span style={{ marginRight: 10 }}>{code}</span>

            <Button
              icon="thunderbolt"
              onClick={() =>
                this.setState({
                  moodleConnection: { visible: true, courseKey: course.key }
                })
              }
              size="small"
              style={{ marginTop: 5 }}
            >
              Connect to Moodle
            </Button>
          </>
        )
      },
      {
        title: "Name",
        key: "name",
        dataIndex: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
        width: 250
      },
      {
        title: "Contact Cards",
        key: "timeslots",
        dataIndex: "timeslots",
        render: (timeslots, course) => {
          const teachingWeeks = moment(course.teaching_end).diff(
            moment(course.teaching_start),
            "weeks"
          );

          return (
            <>
              <div>
                <Button
                  size="small"
                  icon="plus"
                  onClick={() =>
                    this.updateTimeslot({
                      type: "add",
                      courseId: course.id
                    })
                  }
                >
                  Add contact card
                </Button>
              </div>

              <div
                style={{
                  marginTop: 10,
                  display: "flex",
                  alignItems: "flex-start",
                  flexWrap: "wrap"
                }}
              >
                {timeslots.map(timeslot => (
                  <Timeslot
                    key={timeslot.id}
                    teachingWeeks={teachingWeeks}
                    courseId={course.id}
                    timeslot={timeslot}
                    updateTimeslot={updatedTimeslot =>
                      this.updateTimeslot({
                        type: "update",
                        courseId: course.id,
                        timeslotId: timeslot.id,
                        updatedTimeslot
                      })
                    }
                    deleteTimeslot={() =>
                      this.updateTimeslot({
                        type: "delete",
                        courseId: course.id,
                        timeslotId: timeslot.id
                      })
                    }
                  />
                ))}
                {!timeslots.length && (
                  <Alert
                    showIcon
                    type="warning"
                    message="At least one contact card must be added to this course."
                    style={{ maxWidth: 450 }}
                  />
                )}
              </div>
            </>
          );
        }
      }
    ];
  };

  onFileDrop = file => {
    const reader = new FileReader();
    reader.addEventListener("load", () =>
      this.setState({ image: reader.result, file })
    );
    reader.readAsDataURL(file);
  };

  getCroppedImage = image => {
    const { crop, file } = this.state;

    if (crop.width === 0 || crop.height === 0) return file;

    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      this.imageRef,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        blob.name = "file.jpeg";
        resolve(blob);
      }, "image/jpeg");
    });
  };

  uploadImage = async () => {
    const { details, consent } = this.state;

    if (!this.imageRef) {
      this.setState({ uploadVisible: false });
      return;
    }

    if (!consent) {
      this.setState({ consentWarning: true });
      return;
    }

    const file = await this.getCroppedImage(this.imageRef);

    const payload = new FormData();
    payload.append("file", file, file.name);

    this.setState({ uploading: true });
    try {
      const response = await axios.post(`profile/picture/`, payload, {
        config: { headers: { "Content-Type": "multipart/form-data" } }
      });
      this.setState({
        uploading: false,
        details: { ...details, picture: response.data.picture },
        uploadVisible: false,
        imageHash: new Date().getTime(),
        imageLoading: true
      });
      notification["success"]({
        message: "Profile picture successfully updated"
      });
    } catch (error) {
      notification["error"]({ message: error.response.data });
      this.setState({ uploading: false });
    }
  };

  ImageModal = () => {
    const {
      uploadVisible,
      image,
      crop,
      uploading,
      consent,
      consentWarning
    } = this.state;

    return (
      <Modal
        visible={uploadVisible}
        title="Update profile picture"
        onCancel={() => this.setState({ uploadVisible: false })}
        onOk={this.uploadImage}
        confirmLoading={uploading}
      >
        {!image ? (
          <>
            <Upload.Dragger
              accept="image/jpeg,image/png"
              name="file"
              beforeUpload={file => {
                this.onFileDrop(file);
                return false;
              }}
            >
              <p className="ant-upload-drag-icon">
                <Icon type="inbox" />
              </p>
              <p className="ant-upload-text">Click or drag image here</p>
            </Upload.Dragger>
          </>
        ) : (
          <div style={{ textAlign: "center" }}>
            <Button onClick={() => this.setState({ image: null })}>
              Choose a different image
            </Button>

            <div
              style={{
                marginTop: 10,
                display: "flex",
                justifyContent: "center"
              }}
            >
              <ReactCrop
                src={image}
                crop={crop}
                onImageLoaded={ref => (this.imageRef = ref)}
                onChange={crop => this.setState({ crop })}
                imageStyle={{ maxHeight: 300, width: "auto" }}
              />
            </div>

            <Alert
              style={{ marginTop: 10 }}
              showIcon
              type={consentWarning ? "error" : "info"}
              message={
                consentWarning
                  ? "You must give consent before your picture can be uploaded."
                  : "The image can be cropped by drag-selecting an area on it."
              }
            />

            <Checkbox
              style={{ marginTop: 10 }}
              onChange={e =>
                this.setState({
                  consent: e.target.checked,
                  consentWarning: false
                })
              }
              checked={consent}
            >
              I consent for my picture to be visible to staff and students
            </Checkbox>
          </div>
        )}
      </Modal>
    );
  };

  onCancelEdit = () => {
    const { form } = this.props;

    if (form.isFieldsTouched()) {
      Modal.confirm({
        title: "Discard changes",
        content:
          "If you proceed, any changes made to your profile details will be lost.",
        onOk: () => {
          this.setState({ editing: false });
        }
      });
    } else {
      this.setState({ editing: false });
    }
  };

  onSave = () => {
    const { form } = this.props;
    const { details } = this.state;

    if (!form.isFieldsTouched()) {
      this.setState({ editing: false });
      return;
    }

    form.validateFields((err, values) => {
      if (err) return;

      const performRequest = async () => {
        this.setState({ saving: true });

        try {
          await axios.put(`profile/details/`, values);
          this.setState({
            saving: false,
            editing: false,
            details: { ...details, ...values }
          });
          notification["success"]({
            message: "Profile details successfully updated"
          });
        } catch (error) {
          notification["error"]({ message: error.response.data });
          this.setState({ saving: false });
        }
      };

      if (values.phone) {
        Modal.confirm({
          title: "Confirm phone number",
          content: `Please be advised that any details you enter here are visible to students. 
            Ensure that the phone number entered is intended to be public.`,
          onOk: () => performRequest(),
          okText: "Confirm"
        });
      } else {
        performRequest();
      }
    });
  };

  deleteImage = e => {
    const { details } = this.state;

    e.stopPropagation();

    Modal.confirm({
      title: "Confirm picture deletion",
      content: "Are you sure you want to delete this picture?",
      onOk: async () => {
        try {
          await axios.delete(`profile/picture/`);
          this.setState({ details: { ...details, picture: null } });
          notification["success"]({
            message: "Profile picture successfully deleted"
          });
        } catch (error) {
          notification["error"]({ message: error.response.data });
        }
      }
    });
  };

  Details = () => {
    const { form } = this.props;
    const {
      details,
      imageLoading,
      imageHash,
      editing,
      saving,
      pictureHover
    } = this.state;
    const { getFieldDecorator } = form;

    return (
      <>
        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: 10
            }}
          >
            <Button
              style={{ marginBottom: 12 }}
              onClick={() => this.setState({ uploadVisible: true })}
              icon="picture"
            >
              Update picture
            </Button>

            <div
              style={{
                height: 150,
                width: 150,
                border: "1px #EEE solid",
                borderRadius: 3,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 3,
                position: "relative"
              }}
              onMouseEnter={() => this.setState({ pictureHover: true })}
              onMouseLeave={() => this.setState({ pictureHover: false })}
            >
              {details.picture ? (
                <>
                  <img
                    src={`${process.env.REACT_APP_ASSET_URL}/${
                      details.picture
                    }?${imageHash}`}
                    alt="Profile"
                    style={{
                      maxHeight: "100%",
                      maxWidth: "100%",
                      display: imageLoading ? "none" : "block"
                    }}
                    onLoad={() => this.setState({ imageLoading: false })}
                  />
                  {imageLoading && <Icon type="loading" size="large" />}
                  <div
                    style={{
                      background: "rgba(0, 0, 0, 0.5)",
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      cursor: "pointer",
                      transition: "0.15s opacity",
                      padding: 3,
                      display: "flex",
                      alignItems: "flex-end",
                      opacity: pictureHover ? 1 : 0
                    }}
                    onClick={() => this.setState({ uploadVisible: true })}
                  >
                    <div style={{ flex: 1 }}>
                      <Tooltip title="Delete picture">
                        <Button
                          icon="delete"
                          type="danger"
                          onClick={this.deleteImage}
                        />
                      </Tooltip>
                    </div>
                    <div style={{ flex: 1, textAlign: "right" }}>
                      <Tooltip title="Update picture">
                        <Button icon="picture" type="primary" />
                      </Tooltip>
                    </div>
                  </div>
                </>
              ) : (
                <Avatar
                  onClick={() => this.setState({ uploadVisible: true })}
                  size="large"
                  icon="user"
                  shape="square"
                  style={{
                    cursor: "pointer",
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: 84
                  }}
                />
              )}
            </div>
          </div>

          <div style={{ maxWidth: 600, flex: 1 }}>
            {!editing && (
              <Button
                icon="edit"
                style={{ marginBottom: 12 }}
                onClick={() => this.setState({ editing: true })}
              >
                Edit contact details
              </Button>
            )}

            {editing && (
              <div style={{ marginBottom: 12 }}>
                <Button type="danger" icon="close" onClick={this.onCancelEdit}>
                  Cancel editing
                </Button>

                <Button
                  type="primary"
                  icon="save"
                  onClick={this.onSave}
                  style={{ marginLeft: 10 }}
                  loading={saving}
                >
                  Save changes
                </Button>
              </div>
            )}

            <Form
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 12 }}
              style={{
                background: "#FAFAFA",
                padding: "5px 20px",
                borderRadius: 4,
                border: "1px solid #EEE"
              }}
              className="user-details"
            >
              <Form.Item label="Display name" style={{ marginBottom: 6 }}>
                {editing
                  ? getFieldDecorator("public_name", {
                      initialValue: details.public_name,
                      rules: [
                        {
                          required: true,
                          message: "Display name is required"
                        }
                      ]
                    })(<Input />)
                  : details.public_name}
              </Form.Item>

              <Form.Item label="Email" style={{ marginBottom: 6 }}>
                {editing
                  ? getFieldDecorator("email", {
                      initialValue: details.email,
                      rules: [
                        {
                          required: true,
                          message: "Email is required"
                        }
                      ]
                    })(<Input />)
                  : details.email}
              </Form.Item>

              <Form.Item label="Phone" style={{ marginBottom: 3 }}>
                {editing ? (
                  getFieldDecorator("phone", {
                    initialValue: details.phone
                  })(<Input placeholder="Not provided" />)
                ) : details.phone ? (
                  details.phone
                ) : (
                  <span style={{ color: "rgba(0, 0, 0, 0.3)" }}>
                    Not provided
                  </span>
                )}
              </Form.Item>
            </Form>
          </div>
        </div>

        <Alert
          showIcon
          type="warning"
          message="Details provided above will be visible to students."
          style={{ maxWidth: 450, marginTop: 10 }}
        />

        {this.ImageModal()}
      </>
    );
  };

  render() {
    const {
      loading,
      details,
      terms,
      currentTerm,
      courses,
      moodleConnection
    } = this.state;

    return (
      <>
        <h2>Your Details</h2>
        <div style={{ marginBottom: 15 }}>
          {!details ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Icon type="loading" size="large" style={{ marginRight: 10 }} />{" "}
              Loading details...
            </div>
          ) : (
            this.Details()
          )}
        </div>

        <h2>Course Contacts</h2>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            maxWidth: 275
          }}
        >
          <div style={{ marginRight: 10 }}>Term:</div>
          <div style={{ flex: 1 }}>
            <Select
              style={{ width: "100%" }}
              disabled={loading}
              loading={loading}
              placeholder="Loading available terms..."
              value={loading ? undefined : currentTerm.toString()}
              onChange={currentTerm => {
                this.setState({ currentTerm }, () => this.getProfile());
                sessionStorage.setItem("currentTerm", currentTerm);
              }}
            >
              {terms.map(term => (
                <Select.Option key={term.code}>
                  {term.label} ({term.code})
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>

        <div style={{ marginTop: 12 }}>
          {currentTerm &&
            !loading &&
            (!courses ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Icon type="loading" size="large" style={{ marginRight: 10 }} />{" "}
                Loading courses...
              </div>
            ) : (
              <>
                {courses.length === 0 ? (
                  <h3>You are not associated with any courses in this term.</h3>
                ) : (
                  <Table
                    bordered
                    columns={this.columns()}
                    dataSource={courses}
                    pagination={false}
                    rowKey={course => course.id}
                  />
                )}
              </>
            ))}
        </div>

        <MoodleConnection
          {...moodleConnection}
          onClose={() =>
            this.setState({ moodleConnection: { visible: false } })
          }
        />
      </>
    );
  }
}

export default Form.create()(Profile);
