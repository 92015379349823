import React, { useState } from "react";
import { notification, Button, Tooltip, Modal } from "antd";
import axios from "axios";

const Finalised = ({
  courseId,
  value,
  tooltip,
  isSystemAdmin,
  updateCourse,
  readOnly
}) => {
  const [loading, setLoading] = useState(false);

  const performRequest = async () => {
    setLoading(true);
    try {
      await axios.post(`course/finalise/`, {
        courses: [courseId],
        value: !value
      });
      updateCourse({ courseId, field: "is_finalised", value: !value });
    } catch (error) {
      notification["error"]({ message: error.response.data });
    }
    setLoading(false);
  };

  const updateFinalisedStatus = () => {
    if (!value) {
      Modal.confirm({
        title: "Confirm finalisation",
        content: `Are you sure you want to finalise this course? If you proceed, ${
          isSystemAdmin
            ? "faculty staff will no longer be able to edit details about this course."
            : "you will be unable to edit the details of this course unless an administrator reverts the finalisation."
        }`,
        okText: "Proceed with finalisation",
        okType: "danger",
        cancelText: "Cancel",
        onOk: performRequest
      });
    } else {
      performRequest();
    }
  };

  return (
    <Tooltip title={tooltip[value]}>
      <Button
        className={`editable-table-button ${readOnly ? "disabled" : ""}`}
        disabled={readOnly || (value && !isSystemAdmin)}
        type={value ? "default" : "danger"}
        shape="circle"
        icon={value ? "check" : "close"}
        size="small"
        onClick={updateFinalisedStatus}
        loading={loading}
        style={{
          background: value && "white",
          color: value && "#43A047",
          cursor: value & !isSystemAdmin ? "default" : "pointer",
          borderColor: value & !isSystemAdmin && "rgb(217, 217, 217)"
        }}
      />
    </Tooltip>
  );
};

export default Finalised;
