import React, { useState, useRef } from "react";
import { notification, Select, Icon } from "antd";
import axios from "axios";

const SurveyType = ({ courseId, classId, value, updateCourse }) => {
  const [loading, setLoading] = useState(false);
  const select = useRef();

  const updateSurveyType = async newValue => {
    setLoading(true);
    try {
      await axios.post(`course/${courseId}/survey_type/`, {
        classes: [classId],
        value: newValue
      });
      updateCourse({
        courseId,
        classId,
        field: "survey_type",
        value: newValue
      });
    } catch (error) {
      notification["error"]({ message: error.response.data });
    }
    setLoading(false);
    select.current.blur();
  };

  return (
    <Select
      className="editable-table-field"
      value={value}
      ref={select}
      onChange={updateSurveyType}
      loading={loading}
      dropdownMatchSelectWidth={false}
      suffixIcon={
        <Icon type="loading" style={{ display: loading ? "block" : "none" }} />
      }
    >
      <Select.Option value="Course Questions">Course Questions</Select.Option>
      <Select.Option value="Teaching Questions">
        Teaching Questions
      </Select.Option>
      <Select.Option value="Course and Teaching Questions">
        Course and Teaching Questions
      </Select.Option>
    </Select>
  );
};

export default SurveyType;
