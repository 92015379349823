import React, { useState, useMemo } from "react";
import { Card, Avatar, Icon, Collapse, Tag, Divider } from "antd";
import moment from "moment";

const days = ["mon", "tue", "wed", "thu", "fri"];

const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const rangeGenarator = (data, type) => {
  let previous = -1;
  let text = "";
  let items = [];

  data.forEach(entry => {
    if (previous !== entry - 1) {
      text += !text
        ? arrayToText(items, type)
        : ", " + arrayToText(items, type);
      items = [];
    }
    items.push(entry);
    previous = entry;
  });
  text += !text ? arrayToText(items, type) : ", " + arrayToText(items, type);

  return text;
};

const arrayToText = (list, type) => {
  if (!list.length) return "";

  if (list.length === 1)
    return type === "weeks"
      ? list.pop()
      : capitalizeFirstLetter(days[list.pop()]);

  return type === "weeks"
    ? list[0] + "-" + list.slice(-1).pop()
    : capitalizeFirstLetter(days[list[0]]) +
        "-" +
        capitalizeFirstLetter(days[list.slice(-1).pop()]);
};

const Timeslot = ({ timeslot }) => {
  const weeks = useMemo(() => timeslot.weeks.sort((a, b) => a - b), [
    timeslot.weeks
  ]);

  return (
    <div
      style={{
        background: "rgba(247, 247, 247, 0.75)",
        padding: "8px 12px",
        margin: "0 -16px 6px -16px"
      }}
    >
      {timeslot.consultation_type === "email" && "Schedule by email"}
      {timeslot.consultation_type === "timeslot" && (
        <div className="timeslot-card">
          <div>
            <div>Weeks</div>
            <div style={{ fontSize: 12 }}>{rangeGenarator(weeks, "weeks")}</div>
          </div>
          <div>
            <div>Days</div>
            <div style={{ fontSize: 12 }}>
              {rangeGenarator(
                timeslot.days.map(value => days.indexOf(value)).sort(),
                "days"
              )}
            </div>
          </div>

          <div>
            <div>Time</div>
            <div
              style={{ display: "flex", alignItems: "center", fontSize: 12 }}
            >
              {moment(timeslot.from_time, "HH:mm:ss").format("HH:mm A")}
              <Divider
                style={{
                  width: 10,
                  minWidth: "auto",
                  margin: "0 5px"
                }}
              />
              {moment(timeslot.to_time, "HH:mm:ss").format("HH:mm A")}
            </div>
          </div>
          <div>
            <div>Location</div>
            <div style={{ fontSize: 12 }}>{timeslot.location}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export const StaffCard = ({ staff }) => {
  const [expanded, setExpanded] = useState(["timeslots"]);

  return (
    <Card
      className="staff-card"
      style={{ width: "100%", marginBottom: 12 }}
      bodyStyle={{ padding: 16 }}
    >
      <Card.Meta
        style={{ marginBottom: 6 }}
        avatar={
          <Avatar
            size="large"
            icon={!staff.picture && "user"}
            shape="square"
            style={{
              width: 70,
              height: 70,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: 56
            }}
            src={
              staff.picture &&
              `${process.env.REACT_APP_ASSET_URL}/${staff.picture}`
            }
          />
        }
        title={staff.public_name}
        description={
          <>
            <div>
              <Icon type="mail" style={{ marginRight: 6 }} />
              <a href={`mailto:${staff.email}`}>{staff.email}</a>
            </div>
            {staff.phone && (
              <div>
                <Icon type="phone" style={{ marginRight: 6 }} />
                {staff.phone}
              </div>
            )}
          </>
        }
      />
      {staff.associations.length > 0 && (
        <div>
          {staff.associations.map((association, i) => (
            <Tag key={i} style={{ margin: "0 3px 3px 0" }}>
              {association.component}-{association.class_section}
            </Tag>
          ))}
        </div>
      )}

      {staff.timeslots.length > 0 && (
        <Collapse
          bordered={false}
          defaultActiveKey={["timeslots"]}
          onChange={e => setExpanded(e)}
        >
          <Collapse.Panel
            header={
              expanded.length
                ? "Hide consultation times"
                : "Show consultation times"
            }
            key="timeslots"
            style={{ borderBottom: 0 }}
          >
            {staff.timeslots.map((timeslot, i) => (
              <Timeslot timeslot={timeslot} key={i} />
            ))}
          </Collapse.Panel>
        </Collapse>
      )}
    </Card>
  );
};

export const EmbeddedStaffCard = ({ staff, type }) => {
  const [expanded, setExpanded] = useState([]);

  return (
    <Card
      className="staff-card embedded"
      style={{ width: "100%", marginBottom: 12 }}
      bodyStyle={{ padding: "10px 5px" }}
    >
      <Card.Meta
        style={{ marginBottom: 0 }}
        avatar={
          <Avatar
            size="large"
            icon={!staff.picture && "user"}
            shape="square"
            style={{
              width: 55,
              height: 55,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: 40
            }}
            src={
              staff.picture &&
              `${process.env.REACT_APP_ASSET_URL}/${staff.picture}`
            }
          />
        }
        title={
          type ? (
            <span
              style={{ fontWeight: "bold", fontSize: "12px", marginBottom: 0 }}
            >
              {staff.public_name}
            </span>
          ) : (
            staff.public_name
          )
        }
        description={
          <>
            <div style={{ whiteSpace: "nowrap" }}>
              <Icon type="mail" style={{ marginRight: 6 }} />
              <a href={`mailto:${staff.email}`}>{staff.email}</a>
            </div>
            {staff.phone && (
              <div>
                <Icon type="phone" style={{ marginRight: 6 }} />
                {staff.phone}
              </div>
            )}
          </>
        }
      />

      {staff.timeslots.length > 0 && (
        <Collapse bordered={false} onChange={e => setExpanded(e)}>
          <Collapse.Panel
            header={
              expanded.length
                ? "Hide consultation times"
                : "Show consultation times"
            }
            key="timeslots"
            style={{ borderBottom: 0 }}
          >
            {staff.timeslots.map((timeslot, i) => (
              <Timeslot timeslot={timeslot} key={i} />
            ))}
          </Collapse.Panel>
        </Collapse>
      )}
    </Card>
  );
};
